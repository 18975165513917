import React, { useEffect, useState } from "react";
import { useNavigate , useLocation} from "react-router-dom";

import Modal from 'react-modal';
import { useProduct } from '../../providers/product'
import { RiCloseFill } from 'react-icons/ri'
import { BiCaretRight } from 'react-icons/bi'
import { api } from "../../services/api";
import Swal from "sweetalert2";
import Spinner from '../spinner';

import iconApprovals from '../../assets/icon_approvalsModal.svg'
import backgroundModal from "../../assets/icon_backgroundModal.png";

import styles from "./styles.module.css";

export default function ModalReplySites(props) {
    const location = useLocation()

    const { isOpenModalReplySites, setIsOpenModalReplySites } = useProduct()

    const [healthChecklist, setHealthChecklist] = useState('')
    const [securityChecklist, setSecurityChecklist] = useState('')
    const [sites, setSites] = useState([])
    const [selectSites, setSelectSites] = useState('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    function handleCloseModal() {
        setIsOpenModalReplySites(false)
        setSelectSites('')
    }

    async function handleReplySiteProduct(){
        Swal.fire({
            title: 'Deseja replicar esse produto?',
            text: 'É necessário que o site siga todas as recomendações!', 
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009EBE',
            confirmButtonText: 'Replicar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const site= selectSites
                const siteUsed = props.sites.flat();
                const allResultSite = [...siteUsed, site]
                await api.put(`/sites/products/${props.id}`,{
                    siteUse: allResultSite
                })

                window.location.reload(false)
                setIsOpenModalReplySites(false)
                setSelectSites('')
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        async function fetchData() {
            const responseSites = await api.get('/sites')
            const site = props.sites?.flat()
            const sites = responseSites.data?.map(e=> 
                e.name
            )
            const sitesUsed = sites?.filter(item => !site?.includes(item))
            setSites(sitesUsed)

            const responseHealthChecklist = await api.get(`health/checklist/${props.id}`)
            if(responseHealthChecklist.data.length > 0){
                setHealthChecklist(responseHealthChecklist.data[0].recommendations)
            }

            const responseSecurityChecklist = await api.get(`security/checklist/${props.id}`)

            if(responseSecurityChecklist.data.length > 0){
                setSecurityChecklist(responseSecurityChecklist.data[0].recommendations)
            }
        }
        fetchData();
    }, [ props.sites]);

    return (
        <>
          {loading == true ? (
                <div className="spinner">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div></div>)}
            <Modal isOpen={isOpenModalReplySites} onRequestClose={handleCloseModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundImage: `url(${backgroundModal})`,
                        backgroundSize: 'cover',
                        backgroundColor: ' transparent  !important',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-14%',
                        transform: 'translate(-50%, -50%)',
                        width: '55%',
                        minHeight:'30%',
                        padding: '0px',
                        borderRadius: '13px',
                    }
                }}>
                <div className={styles.main}>
                    <div className={styles.title}>
                        <div style={{ display: 'flex', alignItems: 'center' }}><img alt='approvals' src={iconApprovals} /> <h1>Replicar para outro site</h1></div>
                        <h2 onClick={handleCloseModal} style={{ fontSize: '150%', cursor: 'pointer' }}><RiCloseFill color="#fff" /></h2>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem'}} >
                        <label>Selecione o site:</label>
                        <select className={styles.select} value={selectSites} multiple={false} onChange={(e) => { setSelectSites(e.target.value) }}>
                            <option value=""></option>
                            {sites?.map((x, i) => <option key={i} value={x}>{x}</option>)}
                        </select>
                    </div>
                    {selectSites !== ''?
                    <div className={styles.table}>
                         <h4 style={{textAlign: 'center', color:' #010066', marginBottom:' 0.5rem'}}>Recomendações:</h4>
                        <div className={styles.divRecommendations}>
                            <div className={styles.recommendations}>
                            <h6 style={{display: 'flex',alignItems: 'center', color:' #010066'}}> 
                            <BiCaretRight color='#010066' /> Saude</h6>
                            {healthChecklist != ''?
                                <p>{healthChecklist}</p>:
                                "Sem recomendações"}
                            </div>
                            
                            <div className={styles.recommendations}>
                            <h6 style={{display: 'flex',alignItems: 'center', color:' #010066'}}>
                                 <BiCaretRight color='#010066' /> Segurança</h6>
                                 {securityChecklist != ''?
                                    <p>{securityChecklist}</p>:
                                    "Sem recomendações"}
                            </div>
                            {/* {securityChecklist != ''?
                            <div className={styles.recommendations}>
                            <h6 style={{display: 'flex',alignItems: 'center', color: '#010066'}}>
                                 <BiCaretRight  color='#010066'/> Qualidade</h6>
                                <p>{securityChecklist}</p>
                            </div>:null} */}
                        </div>
                        <div className={styles.button}>
                            <button onClick={()=> handleReplySiteProduct()}>Replicar</button>
                        </div>

                    </div>:null}
                </div>
            </Modal>
        </>
    )
}