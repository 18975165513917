import React, { useState } from "react";

import { RiCloseFill } from 'react-icons/ri'
import Modal from 'react-modal';

import { useProduct } from "../../providers/product";

export default function ViewPic(props) {

    const { isOpenModalPic, setIsOpenModalPic } = useProduct()
    const { uploadFotos } = useProduct()

    function handleCloseModal() {
        setIsOpenModalPic(false)
    } 
    return (
        <>
            <Modal isOpen={isOpenModalPic} onRequestClose={handleCloseModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor:' rgb(0 0 0 / 20%)'
                    },
                    content: {
                        position: 'absolute',
                        width: 'fit-content',
                        height: 'fit-content',
                        top: '15%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        border: 'none',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '5px',
                        outline: 'none',
                        padding: 'none',

                    }
                }}>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <h2 onClick={handleCloseModal} style={{ position: 'absolute', margin: '1rem', fontSize: '150%', cursor: 'pointer' }}><RiCloseFill color='red' /></h2>
                </div>

                <img src={uploadFotos} style={{ maxHeight: '70vh', maxWidth: '80vw' }} />
            </Modal>
        </>
    )
}