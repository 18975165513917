import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { api } from "../../services/api";
import Chart from 'react-apexcharts'

import Header from "../../components/header";
import TitlePages from '../../components/titlePages'
import Spinner from '../../components/spinner';
import LabelForms from '../../components/labelForms'

import iconReport from '../../assets/icon_report.svg'
import { AiOutlineSearch } from 'react-icons/ai'
import { RiCloseFill } from 'react-icons/ri'

import { CheckPicker } from 'rsuite'
import { InputPicker } from 'rsuite';
import { DateRangePicker } from 'rsuite';

import style from './reports.module.css'
import moment from "moment";
import ChartProductBymonth from './charts/chartProductByMonth';
import ChartProductByStatus from './charts/chartProductByStatus';
import ChartProductByAreas from './charts/chatProductByAreas';
import ChartProductByGroup from './charts/chartProductByGroup';
import ChartProductByInactive from './charts/chartProductByInactive';

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    width: 400,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default function Reports() {
    const navigate = useNavigate()

    const [productsReports, setproductsReports] = useState([])
    const [products, setProducts] = useState([])
    const [sites, setSites] = useState([])
    const [areasUse, setAreasUse] = useState([])
    const [productGroup, setProductGroup] = useState([])
    const [users, setUsers] = useState([])


    const [dateReports, setDateReports] = useState('')
    const [selectSites, setSelectSites] = useState([])
    const [selectStatus, setSelectStatus] = useState([])
    const [selectAreasUse, setSelectAreasUse] = useState([])
    const [selectProductGroup, setSelectProductGroup] = useState([])
    const [selectControlledPFPAPC, setSelectControlledPFPAPC] = useState([])
    const [selectUsers, setSelectUsers] = useState([])

    const [loading, setLoading] = useState([])
    const [series, setSeries] = useState([]);

    async function getSites() {
        setLoading(true)
        let responseSites = await api.get('/sites')
        const dataSites = responseSites.data.map(item => ({ label: item.name, value: item.id }));
        setSites(dataSites)
        setLoading(false)
    }

    async function getArea() {
        setLoading(true)
        let response = await api.get('/areasuse')
        const dataAreas = response.data.map(item => ({ label: item.name, value: item.id }));
        setAreasUse(dataAreas)
        setLoading(false)
    }

    async function getUsers() {
        setLoading(true)
        let response = await api.get('/employees')
        const dataUsers = response.data.map(item => ({ label: item.name, value: item.id }));
        setUsers(dataUsers)
        setLoading(false)
    }

    async function getGroup() {
        setLoading(true)
        let response = await api.get('/productsgroup')
        const dataProductGroup = response.data.map(item => ({ label: item.name, value: item.id }));
        setProductGroup(dataProductGroup)
        setLoading(false)
    }
    
    async function getProducts() {
        setLoading(true)
        let response = await api.get('/products')
        setProducts(response.data)
        setLoading(false)
    }

    useEffect(() => {
        getSites()
        getArea()
        getGroup()
        getUsers()
        getProducts() 
    }, []);

    const dataStatus = ['Homologado', 'Reprovado', 'Em análise', 'Aguardando aprovação', 'Inativo'].map(item => ({ label: item, value: item }));
    const dataControlledPFPAPC = ['Sim', 'Não'].map(item => ({ label: item, value: item }));
    

    async function handleFilterReports() {
        setLoading(true)

        const dashboard = await api.get('/products', {
            params: {
              controlledPFFAPC: selectControlledPFPAPC,
              siteIds: selectSites.map(e => e),
              areaIds: selectAreasUse.map(e => e),
              groupIds: selectProductGroup.map(e => e),
              period: dateReports,
              status: dataStatus.map(e => e.value),
              userIds:selectUsers.map(e => e),
            }
          })
        setProducts(dashboard.data)
        setLoading(false)
    }

    async function clearFilterReports() {
        setLoading(true)
        setproductsReports([])
        //setStartDateReports('')
        //setEndDateReports('')
        setLoading(false)
    }
    return (
        <div className={style.main}>
            <Header funcao={() => navigate('/home')} />
            <div className={style.container}>
                {loading == true ? (
                    <div className="spinner">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <div></div>
                )}
                <TitlePages icon={iconReport} styles={{ fontSize: '1.2rem' }} title='Relatório Quantitativo dos produtos' />
                <div className={style.containerReport}>
                    <div className={style.selectData}>

                        <div className={style.select}>
                            <span><strong>Escolha os sites:</strong></span>
                            <CheckPicker placeholder="Selecione" value={selectSites} onChange={setSelectSites} data={sites} style={{ width: 224 }} />
                        </div>

                        <div className={style.select}>
                            <span><strong>Escolha os status:</strong></span>
                            <CheckPicker placeholder="Selecione" value={selectStatus} onChange={setSelectStatus} data={dataStatus} style={{ width: 224 }} />
                        </div>

                        <div className={style.select}>
                            <span><strong>Escolha as áreas de ultilização:</strong></span>
                            <CheckPicker placeholder="Selecione" value={selectAreasUse} onChange={setSelectAreasUse} data={areasUse} style={{ width: 224 }} />
                        </div>

                        <div className={style.select}>
                            <span><strong>Escolha os grupos dos produtos:</strong></span>
                            <CheckPicker placeholder="Selecione" value={selectProductGroup} onChange={setSelectProductGroup} data={productGroup} style={{ width: 224 }} />
                        </div>

                        <div className={style.select}>
                            <span><strong>Controlado por PF/FA/PC:</strong></span>
                            <InputPicker placeholder="Selecione" value={selectControlledPFPAPC} onChange={setSelectControlledPFPAPC} data={dataControlledPFPAPC} style={{ width: 224 }} />
                        </div>

                        <div className={style.select}>
                            <span><strong>Escolha os usuário:</strong></span>
                            <CheckPicker placeholder="Selecione" value={selectUsers} onChange={setSelectUsers} data={users} style={{ width: 224 }} />
                        </div>

                        <div className={style.select}>
                            <span><strong>Escolher período:</strong></span>
                            <DateRangePicker ranges={[]} value={dateReports} onChange={setDateReports} placeholder="Selecione as datas" style={{ width: 224 }} />
                        </div>

                        <div style={{display: 'flex', alignItems: 'end'}} >
                            <button style={{ background: '#fff', color: '#2EAAC4' }} type="button" className={style.buttonsCalendar} onClick={() => clearFilterReports()}><RiCloseFill size={20} /></button>
                            <button type="button" className={style.buttonsCalendar} onClick={() => handleFilterReports()}><AiOutlineSearch size={18} /></button>
                        </div>
                    </div>

                    <div className={style.charts}>
                        <ChartProductBymonth products={products} dateReports={dateReports} />
                        <ChartProductByStatus products={products} />
                        <ChartProductByAreas products={products}/>
                        <ChartProductByGroup products={products}/>
                        <ChartProductByInactive 
                        selectControlledPFPAPC={selectControlledPFPAPC}
                        selectSites={selectSites}
                        selectAreasUse={selectAreasUse}
                        selectProductGroup={selectProductGroup}
                        dateReports={dateReports}
                        dataStatus={dataStatus}
                        selectUsers={selectUsers}
                        productsActive={products}/>
                    </div>
                </div>
            </div>
        </div>
    )
}