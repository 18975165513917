import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";

import styles from "../login/login.module.css";
import { api } from "../../services/api";
import logo from "../../assets/Grupo.png"
import Spinner from '../../components/spinner';


export default function Register() {
  const navigate = useNavigate()

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  const [captcha, setCaptcha] = useState('');
  const [loading, setLoading] = useState([])

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    timerProgressBar: true,
    showConfirmButton: false,
    timer: 3000,
    width: 400,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  async function onSubmit() { // autentica o usuario e salva o token e redireciona para a home
    try {
      setLoading(true)
      let response = await api.post('register',
        {
          "name": name,
          "email": email
        },
        {
          params: {
            recaptcha: captcha
          }
        })

      if (response.status == 200) {
        Swal.fire({
          title: 'Cadastro',
          text: "Um link foi enviado para seu e-mail. Após o recebimento, sua validade é de 12 horas.",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#46B8D3',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/";
          }
        })
      }
    } catch (err) {

      window.grecaptcha.reset();
      setIsVerify(false)
      if (err == 'Requisição ja efetuada, verifique seu email') {
        Swal.fire({
          title: 'Alteração de senha',
          text: "Um link já foi enviado para seu e-mail. Por favor verifique sua caixa de e-mails e spam. Somente é possível solicitar um novo link após o período de expiração de 12 horas.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#46B8D3',
          confirmButtonText: 'Ok'
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: `${err.response.data.toString()}`
        })
      }
    }
    setLoading(false)

  }

  function onChange(event) {
    setIsVerify(true)
    setCaptcha(event)
  }
  return (
    <>
      {loading == true ? (
        <div className="spinner">
          <Spinner animation="border" />
        </div>
      ) : (
        <div></div>)}
      <div className={styles.main}>
        <div className={styles.containerHome}>
          <img alt='logo' src={logo} />
          <div className={styles.containerButtons}>
            <div className={styles.title}>
              <span className={styles.titleFirst}>Catálogo de</span>
              <span className={styles.titleFirst}><strong>Produtos Químicos</strong></span>
            </div>
            <div className={styles.loginright}>
              <h1>Registrar</h1>
              <div className={styles.inputsContainer}>
                <p>Nome:</p>
                <input type="text"
                  name="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />

                <p>Email:</p>
                <div>
                  <input
                    style={{ marginLeft: '1.5rem' }}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />

                </div>
              </div>
              <div className={styles.recaptcha}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={onChange}
                />
              </div>
              <div className={styles.buttons}>
                <button className={styles.divButton} disabled={!isVerify} onClick={() => onSubmit()} >Registrar</button>
                <button className={styles.divButtonRegister} onClick={() => window.location.href = "/"}> Login </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}