import React, { useState } from 'react';
import Swal from "sweetalert2";

import { useParams, useNavigate } from "react-router-dom";
import { api } from "../../services/api";

import ReCAPTCHA from "react-google-recaptcha";
import logo from "../../assets/Grupo.png"
import styles from "../login/login.module.css";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import Spinner from '../../components/spinner';

export default function RegisterPassword() {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
  const togglePasswordConfirmVisiblity = () => {
    setPasswordConfirmShown(passwordConfirmShown ? false : true);
  };

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState([])

  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  let { id } = useParams();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    timerProgressBar: true,
    showConfirmButton: false,
    timer: 3000,
    width: 400,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  function validation() {
    let validation = true

    if (password == '') {
      setPasswordError('Digite uma senha.')
      validation = false
    }

    if (confirmPassword == '') {
      setConfirmPasswordError('Digite a confirmação de senha.')
      validation = false
    }

    if (password.length < 8) {
      setPasswordError('Senha muito curta.')
      validation = false
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('As senhas não coincidem.')
      validation = false
    }
    return validation
  }

  async function onSubmit() {
    try {
      setLoading(true)

      if (validation()) {
        let response = await api.post('/registerpassword', {
          "password": password,
          "id": id
        })

        if (response.status == 200) {
          Swal.fire({
            text: 'Senha registrada com sucesso!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#46B8D3',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/";
            }
          })
        }
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: `${error.response.data.toString()}`
      })
    }
    setLoading(false)

  }

  return (
    <>
    {loading == true ? (
      <div className="spinner">
        <Spinner animation="border" />
      </div>
    ) : (
      <div></div>)}
    <div className={styles.main}>
      <div className={styles.containerHome}>
        <img alt='logo' src={logo} />
        <div className={styles.containerButtons}>
          <div className={styles.title}>
            <span className={styles.titleFirst}>Catálogo de</span>
            <span className={styles.titleFirst}><strong>Produtos Químicos</strong></span>
          </div>
          <div className={styles.loginright}>
          <h1>Senha</h1>
          <div className={styles.inputsContainer}>
            <p>Senha:</p>
            <div>
              <input value={password} onChange={(event) => {
                setPasswordError('')
                setPassword(event.target.value)
              }}
                type={passwordShown ? "text" : "password"} />
              {passwordShown == true ?
                <AiFillEyeInvisible color='#000' onClick={togglePasswordVisiblity} /> :
                <AiFillEye color='#000' onClick={togglePasswordVisiblity} />}
            </div>
            {passwordError ? (
              <div className="inputError">{passwordError}</div>
            ) : null}

            <p> Confirmação da senha:</p>
            <div>
              <input value={confirmPassword} onChange={(event) => {
                setConfirmPasswordError('')
                setConfirmPassword(event.target.value)
              }}
                type={passwordConfirmShown ? "text" : "password"}
              />
              {passwordConfirmShown == true ?
                <AiFillEyeInvisible color='#000' onClick={togglePasswordConfirmVisiblity} /> :
                <AiFillEye color='#000' onClick={togglePasswordConfirmVisiblity} />}
            </div>
            {confirmPasswordError ? (
              <div className="inputError">{confirmPasswordError}</div>
            ) : null}
          </div>
            
            <div className={styles.buttons}>
              <button className={styles.divButton}  onClick={() => onSubmit()} > Salvar </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}