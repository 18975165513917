import React from "react";
import style from "./text.module.css";

export default function TextPages(props) {

  return (
    <>
      <div className={style.textPages}>
        <h4>{props.title}</h4>
      </div>
    </>
  )
}