import { useState } from 'react';
import { useNavigate, Link } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import logo from "../../assets/Grupo.png"
import Spinner from '../../components/spinner';

import styles from "./login.module.css";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import { api } from "../../services/api";


export default function Login() {
  const navigate = useNavigate()

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  const [captcha, setCaptcha] = useState('');
  const [loading, setLoading] = useState([])

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    timerProgressBar: true,
    showConfirmButton: false,
    timer: 3000,
    width: 400,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  async function onSubmit() { // autentica o usuario e salva o token e redireciona para a home
    try {
      setLoading(true)
      let response = await api.post('authenticate',
        {
          "email": email,
          "password": password
        },
        {
          params: {
            recaptcha: captcha
          },
          withCredentials: true,
        }
      )

      if (response.status == 200) {
        Toast.fire({
          icon: 'success',
          title: 'Login efetuado com sucesso!'
        })
        setEmail('')
        setPassword('')

      }
      navigate('/home')
    } catch (err) {
      window.grecaptcha.reset();
      setIsVerify(false)
      console.log(err)

      Toast.fire({
        icon: 'error',
        title: `${err.response.data.toString()}`
      })

    }
    setLoading(false)
  }

  function onChange(event) {
    setIsVerify(true)
    setCaptcha(event)
  }
  return (
    <>
      {loading == true ? (
        <div className="spinner">
          <Spinner animation="border" />
        </div>
      ) : (
        <div></div>)}
      <div className={styles.main}>
        <div className={styles.containerHome}>
          <img alt='logo' src={logo} />
          <div className={styles.containerButtons}>
            <div className={styles.title}>
              <span className={styles.titleFirst}>Catálogo de</span>
              <span className={styles.titleFirst}><strong>Produtos Químicos</strong></span>
            </div>
            <div className={styles.loginright}>
              <h1>Login</h1>
              <div className={styles.inputsContainer}>
                <p>Email:</p>
                <input type="text"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />

                <p>Senha:</p>
                <div>
                  <input
                    style={{ marginLeft: '1.5rem' }}
                    type={passwordShown ? "text" : "password"}
                    name="senha"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  {passwordShown == true ?
                    <AiFillEyeInvisible color='#000' onClick={togglePasswordVisiblity} /> :
                    <AiFillEye color='#000' onClick={togglePasswordVisiblity} />}
                </div>
              </div>
              <button type="button" className={styles.buttonReset} onClick={() => window.location.href = "/reset"}>Esqueceu sua senha?</button>
              <div className={styles.recaptcha}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={onChange}
                />
              </div>
              <div className={styles.buttons}>
                <button className={styles.divButton} disabled={!isVerify} onClick={() => onSubmit()} type="button">Entrar</button>
                <button className={styles.divButtonRegister} style={{ marginBottom: '1rem'}} type="button" onClick={() => window.location.href = "/register"}>Registrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}