import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from 'react-modal';
import { useProduct } from '../../providers/product'
import { api } from "../../services/api";

import QuestionsQualidade from "../questionsQualidade";
import QuestionsSaude from "../questionsSaude";
import QuestionsSegurança from "../questionsSegurança";
import backgroundModal from "../../assets/icon_backgroundModal.png";

export default function ModalChecklist(props) {

    const { openModalChecklist, setOpenModalChecklist } = useProduct()
    const [isApprover, setIsApprover] = useState('')
    const [name, setName] = useState('')
    const [id, setId] = useState('')

    const navigate = useNavigate()

    function handleCloseModal() {
        setOpenModalChecklist(false)
    }

    useEffect(() => {
        async function fetchData() {
            const userId =  sessionStorage.getItem('userId');
            let response = await api.get(`/user/approvals/${userId}`)
            const isName =  sessionStorage.getItem('name');
            setIsApprover(response.data[0].areaApprovalsId)
            setName(isName)
            setId(userId)
        }
        fetchData();
    }, []);

    return (
        <>
            <Modal isOpen={openModalChecklist} onRequestClose={handleCloseModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundImage: `url(${backgroundModal})`,
                        backgroundSize: 'cover',
                        backgroundColor:' transparent  !important',
                    },

                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-14%',
                        transform: 'translate(-50%, -50%)',
                        width: '65%',
                        borderRadius:'13px',
                        padding:'0px'
                    }
                }}>
                {props.checklistType == 'Qualidade' ?
                    <QuestionsQualidade name={name} id={id} productId={props.id} />
                    :props.checklistType == 'Saúde' ?
                        <QuestionsSaude name={name} id={id} productId={props.id} />
                        : props.checklistType == 'Segurança' ?
                            <QuestionsSegurança name={name} id={id} productId={props.id} /> : 
                             <QuestionsSegurança name={name} id={id} productId={props.id} />}
            </Modal>
        </>
    )
}