import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx'
import moment from 'moment';

import { api } from "../../services/api";
import { tableIcons } from "../../assets/icons-table/icons";
import { FaFilter } from 'react-icons/fa'
import { ImPlus } from 'react-icons/im'
import icon_lineGreen from '../../assets/icon_lineGreen.png'
import icon_lineRed from '../../assets/icon_lineRed.png'
import icon_lineYellow from '../../assets/icon_lineYellow.png'
import icon_lineOrange from '../../assets/icon_lineOrange.png'
import Spinner from '../../components/spinner';

import style from './searchProducts.module.css'
import Header from "../../components/header";
import TitlePages from '../../components/titlePages'

import iconSearch from '../../assets/icon_search.svg'
import Button from "../../components/button";

export default function SearchMyProducts() {

    const navigate = useNavigate()
    const [products, setProducts] = useState([])
    const [filtering, setFiltering] = useState(false);
    const siteId = sessionStorage.getItem('site')
    const [loading, setLoading] = useState([])

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            if (siteId !== '') {
                let response = await api.get(`/sites/myproducts/${siteId}`)
                setProducts(response.data)
            } else {
                let response = await api.get('/myproducts')
                setProducts(response.data)
            }
            setLoading(false)
        }
        fetchData();
    }, []);

    const columns = [
        {
            title: "Produto",
            field: "productName",

        },
        {
            title: "Fabricante",
            field: "manufacturer",
        },
        {
            title: "Grupo",
            width: "10%",
            field: "ProductGroup.name",
        },
        {
            title: "Solicitante",
            field: "Users.name",
            width: "100%",
        },
        {
            title: "Status",
            field: "status",
        },
        {
            title: <div onClick={() => { setFiltering(currentFilter => !currentFilter) }}><FaFilter fontSize="medium" /></div>,
            field: "status",
            width: "10%",
            align: "right",
            filtering: false,
            render: (rowData) => 
            rowData.status === 'Homologado' ? 
            <div className={style.statusCircle}>
                <img alt='lineGreen' src={icon_lineGreen} />
            </div> 
             :rowData.status === 'Reprovado' ?
            <div className={style.statusCircle}>
                <img alt='lineRed' src={icon_lineRed} />
            </div>
            :rowData.status === 'Em análise' ?
            <div className={style.statusCircle}>
                <img alt='lineYellow' src={icon_lineYellow} />
            </div>:
            <div className={style.statusCircle}>
                <img alt='lineYellow' src={icon_lineOrange} />
            </div>
        },
    ];

    function onRowClick(record, index) {
        navigate('/view-product', { state: { id: record, viewMyProduct: true} })
    }


    const downloadCsv = async (data) => {
        console.log(data)
        let newData = []
        for (const row of data){
            newData.push({
                Data_Solitação: moment(row.requestDate).format('DD/MM/YYYY'),
                Solicitante: row.Users.name,
                Cargo: row.responsibility,
                Site_Solicitante: row.requestingSite.name,
                Controlado_PF_FA_PC: row.controlledPFFAPC,
                Grupo_Produto: row.ProductGroup.name,
                Princípio_Ativo: row.activePrinciple,
                Ação_Produto: row.productAction,
                Áreas_Ultilização: row.AreasUse.name,
                Fabricante: row.manufacturer,
                Produto_RET: row.RETProduct,
                Sites_Utilização: row.siteUse.toString(),
                Atividade_Produto: row.productAction,
                Foi_Aberto_MOC: row.MOCOpened == 'true'? 'VERDADEIRO': 'FALSO',
            })
        }
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "CPQ_My_Products")
        //Buffer
        // let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "CPQ_My_Products.xlsx")
    }

    return (
        <>
        {loading == true ? (
                <div className="spinner">
                    <Spinner animation="border" />
                </div>
            ) : (<div></div>)}
        <div className={style.background}>
            <Header funcao={() => navigate('/home')} />
            <div>
                <div className={style.main}>
                    <TitlePages icon={iconSearch} styles={{ fontSize: '1.2rem' }} title='Minhas solicitações' />
                    <div className={style.container}>
                        <MaterialTable
                            columns={columns}
                            
                            data={products}
                            icons={tableIcons}
                            title=''
                            onRowClick={(event, rowData) => { onRowClick(rowData.id) }}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: "Nenhum produto disponível",
                                },
                                toolbar: {
                                    searchPlaceholder: "Buscar",
                                    exportTitle: '',
                                },

                            }}
                            options={{
                                exportButton: true,
                                exportCsv: (columns, data) => {
                                    downloadCsv(data);
                                },
                                filtering: filtering,
                                search: true,
                                maxBodyHeight: '62vh',
                                minBodyHeight: '62vh',
                                rowStyle: {
                                    fontSize: '0.9rem',

                                }, headerStyle: {
                                    backgroundColor: '#2EAAC4',
                                    color: '#FFF',

                                }, headerStyleHover: {
                                    backgroundColor: 'var(--dark-blue)',
                                    color: '#FFF',
                                },
                                paging: false
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}