import React, { useEffect } from "react";

import { useProduct } from "../../providers/product";

import ViewPic from "../viewPic";
import { FaFilePdf, FaFileExcel } from 'react-icons/fa'

import styles from './file.module.css'
import { Tooltip } from '@mui/material';

export default function ViewUpload(props) {

    const { setIsOpenModalPic } = useProduct()
    const { setUploadFotos } = useProduct()

    function openImg(photo) {
        setIsOpenModalPic(true)
        setUploadFotos(photo)
    }
    return (
        <div >
            <div>
                {props.anexo?.length !== 0 ?
                    <div className={styles.imgDiv}>
                        {props.anexo?.map(x => {
                            return (
                                <div key={x.name} className={styles.imgContainer}>
                                    {x.type?.split('/')[1] === 'pdf' ?
                                        <a href={x.link} className={styles.anexosDiv} data-tip data-for={`pdf`}>
                                            <Tooltip title={x.name} placement="top">
                                                <button>
                                                    <FaFilePdf color='#000' size={70} />
                                                </button>
                                            </Tooltip>
                                        </a>
                                        : x.type?.split('/')[1] === 'csv' ?
                                            <a href={x.link} className={styles.anexosDiv}>
                                                <Tooltip title={x.name} placement="top" >
                                                    <button>
                                                        <FaFileExcel color='#000' size={70} />
                                                    </button>

                                                </Tooltip>
                                            </a>
                                            :
                                            <div key={x.name} className={styles.imgContainer}>
                                                <img className={styles.img} alt='' title={x.name} src={x.link} onClick={() => openImg(x.link)} />
                                            </div>
                                    }
                                    {props.name == 'FISPQ' ?
                                    <div className={styles.inputContainer}>
                                        <label>Vencimento:</label>
                                        <input type='date' disabled  value={x.validUntil}/>
                                    </div>:null }
                                </div>
                            )
                        })
                        }
                         
                    </div>
                    : <p style={{marginLeft:' 0.5rem'}}>Nenhum anexo {props.name}</p>}
            </div>
            <div>
            </div>
            <ViewPic />
        </div >
    )
}