import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import style from '../../administration/adm.module.css'
import { api } from "../../../services/api";

import Table from 'rc-table';
import Swal from 'sweetalert2';

import { FaMountain } from 'react-icons/fa'
import { FaCheck, FaTimes, FaTrash } from 'react-icons/fa';
import { RiPencilFill } from 'react-icons/ri';


import TitlePages from "../../../components/titleConfigs";
import Label from "../../../components/label";
import AddButton from "../../../components/buttonAdd";
import Spinner from '../../../components/spinner';

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 2000,
  width: 400,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default function Sites() {

  const navigate = useNavigate()
  const [loading, setLoading] = useState([])
  const [sites, setSites] = useState([])
  const [newSites, setNewSites] = useState([])
  const [newSigla, setNewSigla] = useState([])

  const [errorSites, setErrorSites] = useState([])

  const [editId, setEditId] = useState('Id');
  const [valueEdit, setValueEdit] = useState([])
  const [valueSiglaEdit, setValueSiglaEdit] = useState([])

  const [inEdit, setInEdit] = useState(false)

  async function getSites() {
    setLoading(true)
    let response = await api.get('sites')
    setSites(response.data)
    setLoading(false)
  }

  useEffect(() => {
    getSites();
  }, []);


  const columns = [
    {
      title: "Site",
      dataIndex: ["name"],
      key: "name",
      align: "left",
      width: '70%',
      render: (val, obj) => Number(editId) === Number(obj.id) ? <input className="inputPadrao" value={valueEdit} onChange={(e) => { setValueEdit(e.target.value); setInEdit(true) }}></input> : <>{val}</>
    },
    {
      title: "Sigla",
      dataIndex: ["sigla"],
      key: "sigla",
      width: '18%',
      render: (val, obj) => Number(editId) === Number(obj.id) ? <input className="inputPadrao" value={valueSiglaEdit} onChange={(e) => { setValueSiglaEdit(e.target.value); setInEdit(true) }}></input> : <>{val}</>
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      align: "right",
      with: '10%',
      render: (value, row, index) => (<div style={{ display: 'flex', height: ' 1.2rem', fontSize: '0.8rem', alignItems: 'center' }}>
        <div className="actionBtn d-none" onClick={(e) => onsubmitEdit(e, row.id)} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><FaCheck color='#5A5A5A' size={14} /></div>&nbsp;
        <div className="actionBtn d-none" onClick={onCancelEdit} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><FaTimes color='#5A5A5A' size={14} /></div>
        <div className="actionBtn" onClick={(e) => onEdit(e, row.name, row.sigla)} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><RiPencilFill color='#5A5A5A' size={14} /></div>&nbsp;&nbsp;
        <div className="actionBtn" onClick={() => handleRemove(row.id)} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><FaTrash color='#5A5A5A' size={14} /></div></div>)

    },
  ];
  let validated = []
  function validation() {

    validated = true
    if (!newSites.trim().length) {
      setErrorSites('Inclusão inválida! É necessário inserir um nome do site antes de adicionar.')
      validated = false
      return false
    }

    if (!newSigla.trim().length) {
      setErrorSites('Inclusão inválida! É necessário inserir uma sigla antes de adicionar.')
      validated = false
      return false
    }

    if (sites.find(site => site.sigla.toUpperCase() === newSigla.trim().toUpperCase()
      || site.name.toUpperCase() === newSites.trim().toUpperCase())
    ) {
      setErrorSites('Inclusão inválida! Nome ou sigla já cadastrado.')
      validated = false
      return false
    }
  }

  async function addSites() {
    validation();

    if (!validated) {
      return;
    }

    try {
      setLoading(true)
      const response = await api.post(`/sites`, {
        name: newSites,
        sigla: newSigla
      })

      if (response.status === 200) {
        Toast.fire({
          title: 'Site cadastrado',
          icon: 'success'
        })
      }
      await getSites();
      setNewSites('')
      setNewSigla('')

    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: `${error.response.data}`
      })
    }
    setLoading(false)

  }

  function handleRemove(id) {
    Swal.fire({
      title: 'Deletar Site?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#009EBE",
      cancelButtonColor: "#636e72",
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true)
          let response = await api.delete(`/sites/${id}`)
          if (response.status == 200) {
            Toast.fire({
              title: 'Site deletado',
              icon: 'success'
            })
          }
          await getSites();
          setLoading(false)
        } catch (error) {
          Toast.fire({
            icon: 'error',
            title: `${error.response.data}`
          })
        }
        setLoading(false)

      }
    })
  }

  async function onsubmitEdit(evt, id) {
    evt.preventDefault();
    const element = evt.target.closest('tr')
    const tdInputs = element.getElementsByTagName('input')
    const tdActionButtons = element.getElementsByClassName('actionBtn')

    for (const input of tdInputs) {
      input.disabled = true
    }

    for (const btn of tdActionButtons) {
      btn.classList.toggle("d-none")
    }

    try {
      if (!valueEdit.trim().length) {
        Toast.fire({
          icon: 'error',
          title: "É necessário inserir um nome antes de adicionar"
        })
        return;
      }

      if (!valueSiglaEdit.trim().length) {
        Toast.fire({
          icon: 'error',
          title: "É necessário inserir uma sigla antes de adicionar"
        })
        return;
      }

      if (valueSiglaEdit.trim().length > 3) {
        Toast.fire({
          icon: 'error',
          title: "A sigla pode ter até 3 letras"
        })
        return;
      }

      if (sites.find(site =>
        (site.sigla.toUpperCase() === valueSiglaEdit.trim().toUpperCase()
          || site.name.toUpperCase() === valueEdit.trim().toUpperCase())
        && site.id !== id)
      ) {
        Toast.fire({
          icon: 'error',
          title: 'Nome ou sigla já cadastrado'
        })
        return;
      }

      setLoading(true)
      let response = []
      if (inEdit) {
        const responseName = await api.put(`/sites/${id}`, {
          name: valueEdit,
          sigla: valueSiglaEdit
        })
        if (responseName.status === 200) {
          response = true
        }
      }
      if (response) {
        Toast.fire({
          icon: 'success',
          title: 'Registro alterado com sucesso!'
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: `${error.response.data}`
      })
    } finally {
      setEditId('Id')
      setInEdit(false)
      await getSites()
      setLoading(false)
    }
  }

  async function onEdit(evt, name, sigla) {
    evt.preventDefault();
    const element = evt.target.closest('tr')
    const itemId = element.getAttribute('data-row-key')
    const tdInputs = element.getElementsByTagName('input')
    const tdActionButtons = element.getElementsByClassName('actionBtn')

    for (const input of tdInputs) {
      input.disabled = false
    }
    for (const btn of tdActionButtons) {
      btn.classList.toggle("d-none")
    }
    setEditId(itemId)
    setValueEdit(name)
    setValueSiglaEdit(sigla)

  }

  function onCancelEdit(evt) {
    evt.preventDefault();
    const element = evt.target.closest('tr')
    const tdInputs = element.getElementsByTagName('input')
    const tdActionButtons = element.getElementsByClassName('actionBtn')

    for (const input of tdInputs) {
      input.disabled = true
    }
    for (const btn of tdActionButtons) {
      btn.classList.toggle("d-none")
    }
    setEditId('Id')
    setInEdit(false)
    setValueEdit([])
  }

  return (
    <>
      <div className={style.admContainer}>
        {loading == true ? (
          <div className="spinner">
            <Spinner animation="border" />
          </div>
        ) : (
          <div></div>
        )}
        <TitlePages icon={<FaMountain color='#009ebe' size={21} />} title='Sites' />
        <div>
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-end', marginTop: '0.25rem', width: '27vw' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
              <Label>Sites</Label>
              <input className={style.inputMain} value={newSites}
                placeholder="Novo Site"
                onChange={(e) => {
                  setErrorSites('')
                  setNewSites(e.target.value)
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
              <Label>Abreviação</Label>
              <input className={style.inputSigla} value={newSigla}
                maxlength="3"
                onkeypress="if (!isNaN(String.fromCharCode(window.event.keyCode)))return !isNaN(event.key)"
                onChange={(e) => {
                  setErrorSites('')
                  setNewSigla(e.target.value)
                }}
              />
            </div>
            <AddButton onClick={() => addSites()} />
          </div>
          {errorSites ? (
            <div className="inputError">{errorSites}</div>
          ) : null}
        </div>

        <div style={{ width: '27vw', overflow: 'auto', padding: '0.1rem', height: '70%' }}>
          <Table
            sticky={true}
            columns={columns}
            showHeader={false}
            data={sites}
            rowKey="id"
            emptyText="Nenhum site cadastrado!"
          />
        </div>
      </div>
    </>
  )
}
