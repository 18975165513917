import React from "react";

import style from './button.module.css'

export default function ButtonAnexos(props) {
    return (
        <div className={style.divButton}>
            <div >
                <p>{props.icon}</p>
                <label>{props.title}</label>
            </div>
        </div>
    )
}