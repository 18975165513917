import Cookie from 'js-cookie';
import { Outlet, Navigate, useNavigate} from 'react-router-dom'
import jwt_decode from "jwt-decode";

const PrivateRoutes = () => {
  const navigate = useNavigate()

  const token=Cookie.get("token_cpq")
  if(token){
    const jwt_Token_decoded = jwt_decode(Cookie.get("token_cpq"));
    console.log(jwt_Token_decoded)
    if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        Cookie.remove('token_cpq')
        navigate('/')
    }  
  }else{
    navigate('/')
  }
 
  return (
    token ? <Outlet /> : <Navigate to="/login" />
  )
}

export default PrivateRoutes