import { useEffect, useState } from "react";

import Chart from 'react-apexcharts'
import { api } from "../../../services/api";

import LabelForms from '../../../components/labelForms'

import style from '../reports.module.css'

export default function ChartProductByInactive(props) {
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({});

    async function getProducts() {
      let response = await api.get('/all/products', {
        params: {
          controlledPFFAPC: props.selectControlledPFPAPC,
          siteIds: props.selectSites.map(e => e),
          areaIds: props.selectAreasUse.map(e => e),
          groupIds: props.selectProductGroup.map(e => e),
          period: props.dateReports,
          status: props.dataStatus.map(e => e.value),
          userIds:props.selectUsers.map(e => e),
        }
      })
        const group = response.data.reduce((acc, item) => {
          if (!acc[item.isActive]) {
            acc[item.isActive] = [];
          }
          acc[item.isActive].push(item);
          return acc;
      }, {})
     
      let productInactive =[]
      for (let [active, product] of Object.entries(group)) {
         productInactive.push(product.length)        
      }

      setOptions({
          labels: ['Ativos', 'Inativo'],
              plotOptions: {
                pie: {
                  donut: {
                    size: '50%',
                  },
                },
              },
              legend: {
                position: 'bottom',
                offsetY: 0,
              
          },
      })
      setSeries(productInactive)
  }

    useEffect(() => {
      getProducts() 
        
    }, [props.productsActive]);
    return (
        <>
            <div className={style.chart}>
                <LabelForms>Produtos Inativos</LabelForms>
                <Chart options={options} series={series} type="donut" />
            </div>
        </>
    )
}