import React from "react";
import moment from 'moment'

import styles from "./textApprovals.module.css";


export default function TextApprovals(props) {

    return (
        <div className={styles.text}>
            <div style={{ width: '17%', color: '#5A5A5A', marginRight: '0.5rem' }}>{props.approvalsName}</div>
            <div style={{ width: '24%', color: '#019934',textAlign: 'center', marginRight: '0.5rem', fontWeight: '800' }}>{props.evaluation}</div>
            <div style={{ width: '53%' , justifyContent: 'space-around', gap: '1rem' }}>{props.name}<p style={{ color: '#009EBE' }}>{moment(props.date).format('DD-MM-YYYY')}</p></div>
        </div>
    )
}