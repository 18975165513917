import axios from 'axios';
import Cookie from 'js-cookie';

const TOKEN=Cookie.get("token_cpq")

export const api = axios.create({
  baseURL: process.env.REACT_APP_HOSTNAME_BACKEND,
  headers: { 
    'Authorization': 'Bearer ' + Cookie.get("token_cpq") 
  }
})

api.interceptors.request.use(
  function(config) {
    const token = Cookie.get("token_cpq") 
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
)


export const addAttachment = async (tableUrl, objToAdd, id) => {
  const formData = new FormData();
  for (const name in objToAdd) {
    if (Object.hasOwnProperty.call(objToAdd, name)) {
      formData.append(name, objToAdd[name]);
    }
  }
  const TOKEN=Cookie.get("token_cpq")
  const res = await fetch(`${process.env.REACT_APP_HOSTNAME_BACKEND}${tableUrl}`, {
    method: "POST",
    body: formData,
    headers: { 'Authorization': 'Bearer ' + TOKEN }
  });
  const data = res;
  return data;
};


