import { useState, useEffect } from 'react';
import ButtonHome from "../../components/buttonHome";
import Header from "../../components/headerHome";
import { useNavigate } from "react-router-dom";

import iconSearch from '../../assets/icon_search.svg'
import iconSolitacoes from '../../assets/icon_solitacoes.svg'
import iconReport from '../../assets/icon_report.svg'
import { GiChemicalDrop } from 'react-icons/gi';
import Spinner from '../../components/spinner';

import { useProduct } from '../../providers/product'
import { api } from "../../services/api";

import style from './home.module.css'
import ModalSolicitacao from "../../components/modalSolicitacao";
import ModalSites from '../../components/modalSites';

export default function Home() {
    const navigate = useNavigate()

    const { setOpenModalSolitacoes } = useProduct()
    const [openMenuCollapsible, setOpenMenuCollapsible] = useState(false)

    const [isAdmin, setIsAdmin] = useState('')
   
    const [isApprover, setIsApprover] = useState('')
    const [name, setName] = useState('')
    const [loading, setLoading] = useState([])

    function openModal() {
        setOpenModalSolitacoes(true)
    }

    useEffect(() => {
        async function getIdentify() {
            setLoading(true)
            try {
                const response = await api.get('identify')
                setName(response.data.name)
                setIsAdmin(response.data.isAdmin)
                setIsApprover(response.data.isApproval)
                sessionStorage.setItem('name', response.data.name);
                sessionStorage.setItem('isAdmin', response.data.isAdmin);
                sessionStorage.setItem('isApprover', response.data.isApproval);
                sessionStorage.setItem('userId', response.data.userId);
                setLoading(false)
            }catch (err) {
                if(err.response.status === 401){
                    window.location.href = "/";
                }
            }
        }
        getIdentify();
    }, []);

    return (
        <>
            {loading == true ? (
                <div className="spinner">
                    <Spinner animation="border" />
                </div>
            ) : (<div></div>)}
            <div className={style.main}>
                <div className={style.containerHome}>
                    <Header name={name} isAdmin={isAdmin} isApprover={isApprover} />
                    <div className={style.containerButtons}>
                        <div className={style.title}>
                            <span className={style.titleFirst}>Catálogo de</span>
                            <span className={style.titleFirst}><strong>Produtos Químicos</strong></span>

                        </div>

                        <div className={style.buttonsHome}>
                            <ButtonHome icon={iconSearch} active={true} label='Buscar ou Solicitar Produtos' route='/products' />
                            <ButtonHome icon={iconSolitacoes} active={true} label='Minhas    Solicitações' route='false' onClick='true' funcao={() => openModal()} />
                            <ButtonHome icon={iconReport} active={true} label='Relatório ' route='/reports-product'  />
                        </div>

                    </div>
                </div>
                <ModalSolicitacao />
                <ModalSites />

            </div>
        </>
    )
}