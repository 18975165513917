import React, { useEffect, useState } from "react";

import styles from "./styles.module.css";

import { RiCloseFill } from 'react-icons/ri'
import iconApprovals from '../../assets/icon_approvalsModal.svg'
import { useProduct } from '../../providers/product'

export default function QuestionsQualidade(props) {

    const { setOpenModalChecklist } = useProduct()

    const date = new Date();
    const futureDate = date.getDate()
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    const [evaluationDate, setEvaluationDate] = useState(defaultValue)
    const [productRegistration, setProductRegistration] = useState([])
    const [workProving, setWorkProving] = useState([])

    const [evaluation, setEvaluation] = useState([])

    function handleCloseModal() {
        setOpenModalChecklist(false)
    }

    return (
        <div className={styles.main}>
            <div className={styles.title}>
                <div style={{ display: 'flex', alignItems: 'center' }}><img alt='approvals' src={iconApprovals} /> <h1>Avaliação de Qualidade</h1></div>
                <h2 onClick={handleCloseModal} style={{ fontSize: '150%', cursor: 'pointer' }}><RiCloseFill color="#fff" /></h2>
            </div>
            <div className={styles.mainAvaliação}>
                <div className={styles.questions}>
                    <div className={styles.divQuestions}>
                        <h2>Data da avaliação?</h2>
                        <input style={{width: '25%'}} type='date' value={evaluationDate} onChange={(e) => setEvaluationDate(e.target.value)} />
                    </div>
                    <div className={styles.divQuestions}>
                        <h2>Produto tem registro aprovado no Ministério da Agricultura para espécie em que será utilizado ?</h2>
                        <input type="radio" id="trueproductRegistration" onChange={(e) => setProductRegistration(e.target.value)} name="productRegistration" value="true" />
                        <label for="trueproductRegistration">Sim</label>
                        <input type="radio" id="falseproductRegistration" onChange={(e) => setProductRegistration(e.target.value)} name="productRegistration" value="false" />
                        <label for="falseproductRegistration">Não</label>
                    </div>
                    <div className={styles.divQuestions}>
                        <h2>Existe trabalho comprovando que o produto não afeta qualidade das sementes ?</h2>
                        <input type="radio" id="trueworkProving" nChange={(e) => setWorkProving(e.target.value)} name="workProving" value="true" />
                        <label for="trueworkProving">Sim</label>
                        <input type="radio" id="falseworkProving" nChange={(e) => setWorkProving(e.target.value)} name="workProving" value="false" />
                        <label for="falseworkProving">Não</label>
                    </div>
                </div>
                <hr/>
                <div className={styles.answers}>
                    <h1>Avaliação </h1>
                    <div className={styles.containerAnswers}>
                        <div className={styles.divAnswers}>
                            <input value="aprovado" onChange={(e) => setEvaluation(e.target.value)} type="radio" id="aprovado" name="Avaliação" />
                            <label for="aprovado">Aprovado</label>
                        </div>
                        <div style={{ flexDirection: 'column' }} className={styles.divAnswers}>
                            <div style={{ display: 'flex' }}>
                                <input value="reprovado" onChange={(e) => setEvaluation(e.target.value)} type="radio" id="reprovado" name="Avaliação" />
                                <label for="reprovado">Reprovado</label>
                            </div>
                            {evaluation == 'reprovado' ?
                                <div style={{ marginTop: '0.5rem' }}>
                                    <textarea style={{ height: '40px' }} placeholder="Justificativa" />
                                </div>
                                : null}
                        </div>
                        <div style={{ flexDirection: 'column' }} className={styles.divAnswers}>
                            <div style={{ display: 'flex' }}>
                                <input value="aprovadoCondições" onChange={(e) => setEvaluation(e.target.value)} type="radio" id="aprovadoCondições" name="Avaliação" />
                                <label for="aprovadoCondições">Aprovado com restrições</label>
                            </div>
                            {evaluation == 'aprovadoCondições'?
                                <div style={{ marginTop: '0.5rem' }}>
                                    <textarea style={{ height: '40px' }} placeholder="Restrições" />
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.divButton}>
                <button>Confirmar</button>
            </div>
        </div>
    )
}