import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import style from '../../administration/adm.module.css'
import { api } from "../../../services/api";

import Table from 'rc-table';
import Swal from 'sweetalert2';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { GoPerson } from 'react-icons/go'
import { FaTrash } from 'react-icons/fa'
import { BsPerson } from 'react-icons/bs'
import InputAdornment from "@material-ui/core/InputAdornment";

import TitlePages from "../../../components/titleConfigs";
import Label from "../../../components/label";
import AddButton from "../../../components/buttonAdd";
import Spinner from '../../../components/spinner';

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    width: 400,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default function Adms() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState([])
    const [selectedAdminName, setSelectedAdminName] = useState('')
    const [adminId, setAdminId] = useState('')

    const [admins, setAdmins] = useState('')
    const [users, setUsers] = useState([])

    async function getUsers() {
        setLoading(true)
        let responseUsers = await api.get('/users')

        let usersArray = []
        responseUsers.data.map(x => {
            usersArray.push({ label: x.name, value: x.id })
        })

        setUsers(usersArray)
        setLoading(false)
    }

    async function getAdmin() {
        setLoading(true)
        let response = await api.get('/admins')
        setAdmins(response.data)
        setLoading(false)
    }

    useEffect(() => {
        getUsers();
        getAdmin();
    }, []);

    const columns = [
        {
            title: "Admins",
            dataIndex: ["name"],
            key: "name",
            align: "left",
            width: '95%',

        },
        {
            title: '',
            dataIndex: '',
            key: '',
            align: "right",
            with: '10%',
            render: (value, row, id) => {
                return (<div onClick={() => handleRemoveAdmin(row.id)} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><FaTrash color='#5A5A5A' size={14} /></div>)
            }
        },
    ];

    function handleRemoveAdmin(id) {
        Swal.fire({
            title: 'Deletar Administrador?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#009EBE",
            cancelButtonColor: "#636e72",
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true)
                    let responseAdmin = await api.put(`/admins/${id}`, {
                        "isAdmin": "false"
                    })
                    if (responseAdmin.status == 200) {
                        Toast.fire({
                            title: 'Administrador deletado',
                            icon: 'success'
                        })
                    }
                    await getAdmin()
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: `${error.response.data}`
                    })
                }
                setLoading(false)
            }
        })
    }

    const [errorAdm, setErroAdm] = useState('')
    let validated = []
    function validation() {
        validated = true
        if (adminId == '') {
            setErroAdm('Inclusão inválida! É necessário pesquisar o colaborador e clicar em seu nome antes de adicionar.')
            validated = false
            return false
        }
        if (admins.filter(x => x.id == adminId).length > 0) {
            Toast.fire({
                title: 'Esse administrador já foi cadastrado.',
                icon: 'error'
            })
            validated = false
            return false
          }
    }


    async function addAdmin() {
        validation();
        if (validated == false) {
            return false
        }
        try {
            setLoading(true)
            let responseAdmin = await api.put(`/admins/${adminId}`, {
                isAdmin: true
            })

            if (responseAdmin.status == 200) {
                Toast.fire({
                    title: 'Administrador cadastrado',
                    icon: 'success'
                })
            }
            await getAdmin()
            setSelectedAdminName('')
            setAdminId('')
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data}`
            })
        }
        setLoading(false)
    }

    function handleSelectedAdmin(value) {
        setAdminId(value.value)
    }

    return (
        <>
            <div className={style.admContainer}>
                {loading == true ? (
                    <div className="spinner">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <div></div>
                )}
                <TitlePages icon={<GoPerson color='#009ebe' size={21} />} title='Cadastro de administradores' funcao={() => navigate('/')} />
                <div>
                    <Label>Administrador</Label>
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'flexStart', marginTop: '0.5rem', width: '27vw', height: '2rem', justifyContent: 'spaceBetween' }}>
                        <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            sx={{ width: 355 }}
                            options={users}
                            inputValue={selectedAdminName}
                            onInputChange={(event, newInputValue) => {
                                setErroAdm('')
                                setAdminId('')
                                setSelectedAdminName(newInputValue);
                            }}
                            onChange={(event, value) => {
                                handleSelectedAdmin(value)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Novo Administrador"
                                    InputProps={{
                                        ...params.InputProps,

                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <BsPerson color="#aaa6a6" size={20} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        />
                        <AddButton onClick={() => addAdmin()} />
                    </div>
                    {errorAdm ? (
                        <div className="inputError">{errorAdm}</div>
                    ) : null}
                </div>

                <div style={{ width: '27vw', overflow: 'auto', padding: '0.1rem', maxWidth: '405px', height: '70%' }}>
                    <Table
                        sticky={true}
                        columns={columns}
                        showHeader={false}
                        data={admins}
                        rowKey="id"
                        emptyText="Nenhum administrador cadastrado!"
                    />
                </div>
            </div>
        </>
    )
}