import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from 'react-modal';
import Table from 'rc-table';
import moment from 'moment'

import { useProduct } from '../../providers/product'
import { RiCloseFill } from 'react-icons/ri'
import { api } from "../../services/api";

import iconSolitacoes from '../../assets/icon_solitacoesModal.svg'
import backgroundModal from "../../assets/icon_backgroundModal.png";

import styles from "./styles.module.css";
export default function ModalSolicitacao(props) {

    const { openModalSolitacoes, setOpenModalSolitacoes } = useProduct()
    const [products, setProducts] = useState([])

    const navigate = useNavigate()

    function handleCloseModal() {
        setOpenModalSolitacoes(false)
    }
    const siteId = sessionStorage.getItem('site')
    useEffect(() => {
        async function fetchData() {
            if (siteId !== null && siteId !== '') {
                let response = await api.get(`/sites/status/myproducts/${siteId}`)
                setProducts(response.data)
            } else {
                let response = await api.get(`/status/myproducts`)
                setProducts(response.data)
            }
        }
    
        fetchData();
    }, []);

    const columns = [
        {
            title: "Data da solicitação",
            field: "requestDate",
            align: "left",
            render: (val, obj) => moment(val.requestDate,).format('DD-MM-YYYY')

        },
        {
            title: "Produto",
            dataIndex: "productName",
            align: "left",
        },
        {
            title: "Site",
            align: "left",
            render: (val, obj) => val.requestingSite?.name
        },
        {
            title: "Status",
            dataIndex: "status",
            align: "left",
        },

    ];

    function openSolitacoes() {
        setOpenModalSolitacoes(false)
        navigate('/my-product')
    }

    return (
        <>
            <Modal isOpen={openModalSolitacoes} onRequestClose={handleCloseModal}
            ariaHideApp={false}
                style={{
                     overlay: {
                        backgroundImage: `url(${backgroundModal})`,
                        backgroundSize: 'cover',
                        backgroundColor:' transparent  !important',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-14%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        height:' 70%',
                        padding:'0px',
                        borderRadius:'13px',
                    }
                }}>
                <div className={styles.main}>
                    <div className={styles.title}>
                        <div style={{display:'flex', alignItems:'center'}}><img alt='approvals' src={iconSolitacoes} /> <h1>Minhas solicitações abertas</h1></div>
                        <h2 onClick={handleCloseModal} style={{ fontSize: '150%', cursor: 'pointer' }}><RiCloseFill color="#fff" /></h2>
                    </div>
                    <div className={styles.table}>
                        <div className={styles.button}>
                            <button onClick={() => openSolitacoes()}>Todas as solicitações</button>
                        </div>
                        <div>
                            <Table
                                sticky={true}
                                showHeader={true}
                                columns={columns}
                                data={products}
                                rowKey='id'
                                emptyText="Nenhum produto para aprovação!"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}