import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { api, addAttachment } from "../../services/api";
import { useProduct } from "../../providers/product"

import Swal from "sweetalert2";

import Header from "../../components/header";
import TitlePages from '../../components/titlePages'

import iconSearch from '../../assets/icon_search.svg'
import { IoAttachOutline } from "react-icons/io5";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { BiBlock, BiCaretRight } from 'react-icons/bi'
import { FaMountain, FaHistory } from 'react-icons/fa'
import Spinner from '../../components/spinner';

import styles from './viewProducts.module.css'
import TextPages from "../../components/textPages";
import ButtonAnexos from "../../components/buttonAnexos";
import TextProduct from "../../components/textProduct";
import moment from 'moment'
import ViewUpload from "../../components/viewUpload";
import TextApprovals from "../../components/textApprovals";
import ModalReplySites from "../../components/modalReplySites";

import TextRequest from "../../components/textRequest";
import UploadFile from "../../components/UploadFile";
import ModalLogsAprovacao from "../../components/modalLogsAprovacao";

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default function ViewProducts() {

    const location = useLocation()
    const id = location.state.id
    const myproducts = location.state.viewMyProduct
    const navigate = useNavigate()
    const [isAdmin, setIsAdmin] = useState('')
    const [isApprover, setIsApprover] = useState('')
    const [userId, setUserId] = useState('')
    const [isEdit, setIsEdit] = useState(false);

    const [product, setProduct] = useState([])
    const [FISPQ, setFISPQ] = useState([])
    const [ficha, setFicha] = useState([])
    const [anexos, setAnexos] = useState([])
    const [sites, setSites] = useState([])

    const [sitesData, setSitesData] = useState([])
    const [areaUseData, setAreaUseData] = useState([])
    const [productGroupData, setProductGroupData] = useState([])

    const [ responsibility, setResponsibility ] = useState([])
    const [ productGrup, setProductGrup ] = useState([])
    const [ site, setSite ] = useState([])
    const [ activePrinciple, setActivePrinciple ] = useState([])
    const [ productAction, setProductAction ] = useState([])
    const [ productName, setProductName ] = useState([])
    const [ controlledPFFAPC, setControlledPFFAPC ] = useState([])
    const [ manufacturer, setManufacturer ] = useState([])
    const [ RETProduct, setRETProduct ] = useState([])
    const [ siteUse, setSiteUse ] = useState([])
    const [ areasUse, setAreasUse ] = useState([])
    const [ isCarbamate, setIsCarbamate ] = useState([])
    const [ isOrganophosphate, setIsOrganophosphate ] = useState([])
    const [ activityUsed, setActivityUsed ] = useState([])
    const [ isMoc, setIsMoc ] = useState([])

    const [ removeAnexo, setRemoveAnexo ] = useState([])

    //FISPQ
    const [ addedFilesFISPQ, setAddedFilesFISPQ ] = useState([])
    const [ selectedFileFISPQ, setSelectedFileFISPQ ] = useState([])
    const [valueFISPQ, setValueFISPQ] = useState([])
    const [dateFISPQ, setDateFISPQ] = useState([])

    //ficha
    const [ addedFilesFicha, setAddedFilesFicha ] = useState([])
    const [ selectedFileFicha, setSelectedFileFicha ] = useState([])


    const [ fotos, setFotos ] = useState([])
    const [ addedFiles, setAddedFiles ] = useState([])
    const [ selectedFile, setSelectedFile ] = useState('')


    const [healthChecklistDate, setHealthChecklistDate] = useState('')
    const [healthChecklistEvaluation, setHealthChecklistEvaluation] = useState('')
    const [healthChecklistName, setHealthChecklistName] = useState('')
    const [healthChecklistRecommendations, setHealthChecklistRecommendations] = useState('')
    const [healthChecklistRestriction, setHealthChecklistRestriction] = useState('')

    const [securityChecklistDate, setSecurityChecklistDate] = useState('')
    const [securityChecklistEvaluation, setSecurityChecklistEvaluation] = useState('')
    const [securityChecklistName, setSecurityChecklistName] = useState('')
    const [securityChecklistRecommendations, setSecurityChecklistRecommendations] = useState('')
    const [securityChecklistRestriction, setSecurityChecklistRestriction] = useState('')

    const [loading, setLoading] = useState([])

    const { setIsOpenModalReplySites } = useProduct()
    const [ openModalLogAprovacoes, setOpenModalLogAprovacoes]= useState(false)

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const approver = sessionStorage.getItem('isApprover');
            const admin =  sessionStorage.getItem('isAdmin');
            const user =  sessionStorage.getItem('userId');

            setIsAdmin(admin)
            setIsApprover(approver)
            setUserId(user)

            let response = await api.get(`/product/${id}`)
                setProduct(response.data)
                setResponsibility(response.data.activityProductUsed)
                setProductGrup(response.data.productGroupId)
                setSite(response.data.requestingSiteId)
                setActivePrinciple(response.data.activePrinciple)
                setProductAction(response.data.productAction)
                setProductName(response.data.productName)
                setControlledPFFAPC(response.data.controlledPFFAPC)
                setManufacturer(response.data.manufacturer)
                setRETProduct(response.data.RETProduct)
                setSiteUse(response.data.siteUse?.join())
                setAreasUse(response.data.areasUseId)
                setActivityUsed(response.data.activityProductUsed)
                setIsMoc(response.data.MOCOpened)
                setSites(response.data.siteUse?.join())

            let responseAttachments = await api.get(`/attachments/products/${id}`)
            let FISPQ = []
            let ficha = []
            let anexo = []
            responseAttachments.data?.forEach(attachment => {

                if (attachment.typeAttachments == 'FISPQ') {
                    FISPQ.push(attachment)
                } else if (attachment.typeAttachments == 'ficha') {
                    ficha.push(attachment)
                } else {
                    anexo.push(attachment)
                }
            })
            setFISPQ(FISPQ)
            setAddedFilesFISPQ(FISPQ)
            setDateFISPQ(FISPQ)
            setFicha(ficha)
            setAddedFilesFicha(ficha)
            setAnexos(anexo)
            setAddedFiles(anexo)
            const responseHealthChecklist = await api.get(`product/health/checklist/${id}`)
            if(responseHealthChecklist.data){
                setHealthChecklistDate(responseHealthChecklist.data.evaluationDate)
                setHealthChecklistEvaluation(responseHealthChecklist.data.evaluation)
                setHealthChecklistName(responseHealthChecklist.data.Users?.name)
                setHealthChecklistRecommendations(responseHealthChecklist.data.recommendations)
                setHealthChecklistRestriction(responseHealthChecklist.data.restriction)
            }
            const responseSecurityChecklist = await api.get(`product/security/checklist/${id}`)
            if(responseSecurityChecklist.data){
                setSecurityChecklistDate(responseSecurityChecklist.data.evaluationDate)
                setSecurityChecklistEvaluation(responseSecurityChecklist.data.evaluation)
                setSecurityChecklistName(responseSecurityChecklist.data.Users?.name)
                setSecurityChecklistRecommendations(responseSecurityChecklist.data.recommendations)
                setSecurityChecklistRestriction(responseSecurityChecklist.data.restriction)
            }
            setLoading(false)
        }
        fetchData();
    }, []);

    useEffect(() => {
        if(isEdit){
            getSites();
            getAreasUse()
            getProductGroup()
        }
    }, [isEdit]);


    async function handleReplySiteProduct() {
        setIsOpenModalReplySites(true)
    }

    async function handleLogsApprovals() {
        setOpenModalLogAprovacoes(true)
    }

    async function getSites() {
        setLoading(true)
        let response = await api.get('sites')
        setSitesData(response.data)
        setLoading(false)
    }

    async function getAreasUse() {
        setLoading(true)
        let response = await api.get('areasuse')
        setAreaUseData(response.data)
        setLoading(false)
    }

    async function getProductGroup() {
        setLoading(true)
        let response = await api.get('productsgroup')
        setProductGroupData(response.data)
        setLoading(false)
    }


    async function handleInactiveProduct() {
        try {
            const { value: text } = Swal.fire({
                title: 'Deseja inativar esse produto?',
                icon: 'warning',
                input: 'textarea',
                inputPlaceholder: 'Justificativa',
                inputAttributes: {
                    'aria-label': 'Justificativa'
                },
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (value !== '') {
                            resolve()
                        } else {
                            resolve('Necessário inserir uma justificativa')
                        }
                    })
                },
                showCancelButton: true,
                confirmButtonColor: '#009EBE',
                confirmButtonText: 'Inativar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await api.put(`/product/${id}`, {
                        justify: result.value,
                        isActive: false
                    })
                    navigate('/products')
                }
            })
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data}`
            })
        }
    }
    let validated = []
    function validation() {

        validated = true
        if (id == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um cargo antes de submeter.`
            })
            validated = false
            return false
        }
        if (siteUse == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um site para utilização antes de submeter.`
            })
            validated = false
            return false
        }
        if (responsibility == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um cargo antes de submeter.`
            })
            validated = false
            return false
        }
        if (productGrup == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um grupo de produto antes de submeter.`
            })
            validated = false
            return false
        }
        if (site == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um site antes de submeter.`
            })
            validated = false
            return false
        }
        if (activePrinciple == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um ativo principal antes de submeter.`
            })
            validated = false
            return false
        }
        
        if (productAction == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um produto ativo antes de submeter.`
            })
            validated = false
            return false
        }
        if (productName == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir o nome do produto antes de submeter.`
            })
            validated = false
            return false
        }
        if (manufacturer == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir o fabricante antes de submeter.`
            })
            validated = false
            return false
        }
        if (siteUse == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir os sites que serão utilizados antes de submeter.`
            })
            validated = false
            return false
        }
        if (areasUse == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir a área de uso antes de submeter.`
            })
            validated = false
            return false
        }

        if (activityUsed == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir a atividade de uso antes de submeter.`
            })
            validated = false
            return false
        }
        if (isMoc == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir se foi aberto um MOC antes de submeter.`
            })
            validated = false
            return false
        }
        if(FISPQ.length === 0){ 
            Toast.fire({
                icon: 'error',
                title: 'Inclusão inválida! É necessário inserir a FISPQ antes de submeter.'
            })
            validated = false
            return false
        }
    }

    async function handleSubmitEdit() {
        validation();
        if (validated == false) {
            return false
        }
        try {
            setLoading(true)
            let response = await api.put(`/edit/products/${id}`, {
                "createdBy": userId,
                "productName": productName,
                "areasUseId": areasUse,
                "responsibility": responsibility,
                "requestingSiteId": site,
                "siteUse": siteUse,
                "activityProductUsed": activityUsed,
                "MOCOpened": isMoc,
                "productGroupId": productGrup,
                "activePrinciple": activePrinciple,
                "RETProduct": RETProduct,
                "controlledPFFAPC": controlledPFFAPC,
                "productAction": productAction,
                "manufacturer": manufacturer,
                "FISPQDueDate": '2025-03-01',
                "status": "Aguardando aprovação"
            })

            if (response.status == 200) {
                if(removeAnexo.length!= 0){
                    console.log(removeAnexo)
                    removeAnexo.map(async (image) => {
                        await api.delete(`/attachment/product/${image.id}`)
                    })
                }
                if (fotos.length != 0) {
                    fotos.map(async (image) => {
                         await addAttachment('attachments/products', {
                            "productId": response.data.id,
                            "typeAttachments": "anexo",
                            "file": image
                        })
                    })
                }
                if (anexos.length != 0) {
                    anexos.map(async (image) => {
                         await addAttachment('attachments/products', {
                            "productId": response.data.id,
                            "typeAttachments": "anexo",
                            "file": image
                        })
                    })
                }
                if (FISPQ.length != 0) {
                    FISPQ.map(async (image) => {
                            await addAttachment('attachments/products', {
                                "productId": response.data.id,
                                "typeAttachments": "FISPQ",
                                "file": image,
                                "validUntil": dateFISPQ.find(e=> e.name == image.name).validUntil
                            })
                    })
                }
                if (ficha.length != 0) {
                    ficha.map(async (image) => {
                       await addAttachment('attachments/products', {
                            "productId": response.data.id,
                            "typeAttachments": "ficha",
                            "file": image
                        })
                    })
                }
                Swal.fire({
                    title: 'Produto editado !',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#009EBE',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/products')
                    }
                })
                setResponsibility()
                setProductGrup()
                setSite()
                setActivePrinciple()
                setProductAction()
                setProductName()
                setControlledPFFAPC()
                setManufacturer()
                setRETProduct()
                setSiteUse()
                setAreasUse()
                setIsCarbamate()
                setIsOrganophosphate()
                setActivityUsed()
                setIsMoc()
                setFISPQ([])
                setFicha([])
                setAnexos([])
                setFotos([])
                setAddedFilesFISPQ([]) 
                setSelectedFileFISPQ([]) 
                setAddedFilesFicha([]) 
                setSelectedFileFicha([]) 
                setAddedFiles([]) 
                setSelectedFile([])
                setValueFISPQ([])

            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data}`
            })
        }
        setLoading(false)
    }

    
    async function handleCancelEdit() {
       setIsEdit(false)
        setResponsibility()
        setProductGrup()
        setSite()
        setActivePrinciple()
        setProductAction()
        setProductName()
        setControlledPFFAPC()
        setManufacturer()
        setRETProduct()
        setSiteUse()
        setAreasUse()
        setIsCarbamate()
        setIsOrganophosphate()
        setActivityUsed()
        setIsMoc()
        setFISPQ([])
        setFicha([])
        setAnexos([])
        setFotos([])
        setAddedFilesFISPQ([]) 
        setSelectedFileFISPQ([]) 
        setAddedFilesFicha([]) 
        setSelectedFileFicha([]) 
        setAddedFiles([]) 
        setSelectedFile([])
        setValueFISPQ([])
    }


    return (
        <>
            {loading == true ? (
                <div className="spinner">
                    <Spinner animation="border" />
                </div>
            ) : (<div></div>)}
            {myproducts == true?
             <Header funcao={() => navigate('/my-product')} />:
            <Header funcao={() => navigate('/products')} />}

            <div className={styles.background}>
                <div className={styles.main}>
                    <div className={styles.status}>
                        <TitlePages styles={{ fontSize: '1.8rem' }} icon={iconSearch} title={product.productName} />
                        <p style={
                        product.status === 'Homologado' ? 
                            {background: '#019934'}
                         :product.status === 'Reprovado' ?
                         {background: '#EF0E18'}
                         :
                         {background: '#FFF200'}
                     } >{product.status}</p>
                    </div>
                    {!isEdit ? 
                    <>
                    <div className={styles.container}>
                        <div className={styles.title}>
                            <h2>Informações Gerais</h2>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <button onClick={() => handleLogsApprovals()}>
                                    <FaHistory color="#ffff" size={15} />
                                    <label>Logs</label>
                                </button>
                                {isAdmin == 'true' || isApprover == 'true' ?
                                    <>
                                    {product.isApproval == true ?
                                        <button onClick={() => handleReplySiteProduct()}>
                                            <FaMountain color="#ffff" size={15} />
                                            <label>Replicar produto para outro site</label>
                                        </button> : null
                                    }

                                    <button onClick={() => handleInactiveProduct()}>
                                        <BiBlock color="#ffff" size={15} />
                                        <label>Inativar produto</label>
                                    </button>

                                    </>
                                :null}

                                {userId == product.createdBy || isAdmin == 'true' && product.status !== "Em análise"?
                                    <>
                                        <button onClick={() => setIsEdit(true)}>
                                            <MdOutlineModeEditOutline color="#ffff" size={15} />
                                            <label>Editar</label>
                                        </button>
                                    </>
                                :null}
                            </div>
                        </div>
                       
                        <div className={styles.mainProduct}>
                            <TextProduct label='Solicitante :' name={product.Users?.name} />
                            <TextProduct label='Cargo :' name={product.responsibility} />
                            <TextProduct label='Site Solicitante :' name={product.requestingSite?.name} />
                            <TextProduct label='Controlado por PF/FA/PC :' name={product.controlledPFFAPC === true ? 'Sim' : 'Não'} />
                            <TextProduct color={true} label='Data da Solitação :' name={moment(product.requestDate).format('DD-MM-YYYY')} />
                            <TextProduct label='Grupo do Produto :' name={product.ProductGroup?.name} />
                            <TextProduct label='Princípio Ativo :' name={product.activePrinciple} />
                            <TextProduct label='Ação do Produto :' name={product.productAction} />
                            <TextProduct label='Áreas de Ultilização :' name={product.AreasUse?.name} />
                            <TextProduct label='Fabricante :' name={product.manufacturer} />
                            <TextProduct label='É produto RET ?' name={product.RETProduct === true ? 'Sim' : 'Não'} />
                            <TextProduct label='Sites para Utilização :' name={sites} />
                            <TextProduct label='Atividade do Produto :' name={product.activityProductUsed} />
                            <TextProduct label='Foi aberto um MOC ?' name={product.MOCOpened == true ? 'Sim' : 'Não'} />
                        </div>
                    </div>
                    <div className={styles.containerAnexos}>
                        <div className={styles.mainAnexos}>
                            <TextPages title='Anexos' />
                            <div className={styles.DivAnexos}>
                                <div className={styles.anexos}>
                                    <ButtonAnexos icon={<IoAttachOutline size={25} color='#fff' />} title='FISPQ' />
                                    <ViewUpload anexo={FISPQ} FISPQ={product.FISPQDueDate} name='FISPQ' />
                                </div>
                                <div className={styles.anexos}>
                                    <ButtonAnexos icon={<IoAttachOutline size={25} color='#fff' />} title='Ficha de emergência' />
                                    <ViewUpload anexo={ficha} name='Ficha de Emergência' />
                                </div>
                                <div className={styles.anexos}>
                                    <ButtonAnexos icon={<IoAttachOutline size={25} color='#fff' />} title='Demais anexos' />
                                    <ViewUpload anexo={anexos} name='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ gap: '1%' }} className={styles.containerAnexos}>
                        <div style={{ paddingRight: '0', width: '80% ' }} className={styles.mainAnexos}>
                            <TextPages title='Restrições' />
                            <div className={styles.textArea}>
                            {healthChecklistRestriction !== ''  || securityChecklistRestriction !== '' ?
                                    <div className={styles.divRecommendations}>
                                        <div className={styles.recommendations}>
                                        {healthChecklistRestriction !== '' ?
                                        <>
                                            <h5 style={{ display: 'flex', alignItems: 'center', color: ' #010066' }}>
                                                <BiCaretRight color='#010066' /> Saude</h5>
                                            <p>{healthChecklistRestriction}</p>
                                        </>:null}
                                        {securityChecklistRestriction !== '' ?
                                        <>
                                            <h5 style={{ display: 'flex', alignItems: 'center', color: ' #010066' }}>
                                                <BiCaretRight color='#010066' /> Segurança</h5>
                                            <p>{securityChecklistRestriction}</p>
                                        </>:null}
                                        </div>
                                    </div>
                                    : <p>Sem restrições</p>}
                            </div>
                        </div>
                        <div style={{ paddingRight: '0', paddingLeft: '0' , width: '80% '}} className={styles.mainAnexos}>
                            <TextPages title='Recomendações' />
                            <div className={styles.textArea}>
                                {healthChecklistEvaluation !== ''  || securityChecklistEvaluation !== '' ?
                                    <div className={styles.divRecommendations}>
                                        <div className={styles.recommendations}>
                                        {healthChecklistRecommendations !== '' ?
                                        <>
                                            <h5 style={{ display: 'flex', alignItems: 'center', color: ' #010066' }}>
                                                <BiCaretRight color='#010066' /> Saude</h5>
                                            <p>{healthChecklistRecommendations}</p>
                                        </>:null}
                                        {securityChecklistRecommendations !== '' ?
                                        <>
                                            <h5 style={{ display: 'flex', alignItems: 'center', color: ' #010066', marginTop: '0.5rem' }}>
                                                <BiCaretRight color='#010066' /> Segurança</h5>
                                            <p>{securityChecklistRecommendations}</p>
                                        </>:null}
                                        </div>
                                    </div>
                                    : <p>Sem recomendações</p>}
                            </div>
                        </div>
                        <div  style={{ paddingLeft: '0'}} className={styles.mainAnexos}>
                            <TextPages title='Avaliações' />
                            {healthChecklistEvaluation !== ''?
                                <TextApprovals approvalsName={'Saúde'} name={healthChecklistName} evaluation={healthChecklistEvaluation} date={healthChecklistDate} /> : null}
                            {securityChecklistEvaluation !=='' ?
                                <TextApprovals approvalsName={'Segurança'} name={securityChecklistName} evaluation={securityChecklistEvaluation} date={securityChecklistDate} /> : null}

                            {healthChecklistEvaluation == '' && securityChecklistEvaluation ==''?
                                <div className={styles.textArea}>
                                    <p>Nenhuma avaliação foi efetuada</p>
                                </div> : null}
                        </div>
                     </div>
                    </> :
                    <div>
                         <div className={styles.container}>
                        <div className={styles.title}>
                            <h2>Informações Gerais</h2>
                        </div>
                        <div className={styles.mainProduct}>
                            <div className={styles.divProduct}>
                                <TextRequest label={'Solicitante :'} />
                                <input value={product.Users?.name} disabled className={styles.inputProduct} />
                            </div>
                         
                            <div className={styles.divProduct}>
                                <TextRequest label='Cargo :' />
                                <input value={responsibility} onChange={(e) => setResponsibility(e.target.value)} className={styles.inputProduct} />
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Site Solicitante* :' />
                                <select className={styles.selectProduct} value={site} multiple={false} onChange={(e) => { setSite(e.target.value) }}>
                                    <option value=""></option>
                                    {sitesData?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                                </select>
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Nome do Produto* :' />
                                <input value={productName} onChange={(e) => setProductName(e.target.value)} className={styles.inputProduct} />
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Fabricante :' />
                                <input value={manufacturer} onChange={(e) => setManufacturer(e.target.value)} className={styles.inputProduct} />
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Grupo de Produto :' />
                                <select className={styles.selectProduct} value={productGrup} onChange={(e) => setProductGrup(e.target.value)} multiple={false}>
                                    <option value=""></option>
                                    {productGroupData?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                                </select>
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Princípio Ativo :' icon={true} info='Consultar informação na FISPQ' />
                                <input value={activePrinciple} onChange={(e) => setActivePrinciple(e.target.value)} className={styles.inputProduct} />
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Ação do Produto :' />
                                <input value={productAction} onChange={(e) => setProductAction(e.target.value)} className={styles.inputProduct} />
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Controlado por PF/FA/PC ?' icon={true} info='Polícia Federal, Forças Armadas e Polícia Civil' />
                                <select className={styles.selectProduct} onChange={(e) => setControlledPFFAPC(e.target.value)} value={controlledPFFAPC}>
                                    <option value=''> </option>
                                    <option value='true'> Sim</option>
                                    <option value="false"> Não</option>
                                </select>
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Produto é RET ?' icon={true} info='Produto é RET' />
                                <select className={styles.selectProduct} onChange={(e) => setRETProduct(e.target.value)} value={RETProduct} >
                                    <option value=''> </option>
                                    <option value='true'> Sim</option>
                                    <option value="false"> Não</option>
                                </select>
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Foi aberto um MOC ?' />
                                <select className={styles.selectProduct} onChange={(e) => setIsMoc(e.target.value)} value={isMoc} >
                                    <option value=''> </option>
                                    <option value='true'> Sim</option>
                                    <option value="false"> Não</option>
                                </select>
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Sites para Utilização :' />
                                <input className={styles.inputProduct} onChange={(e) => setSiteUse(e.target.value)} value={siteUse} />
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Áreas de Utilização :' />
                                <select className={styles.selectProduct}  value={areasUse}  onChange={(e) => setAreasUse(e.target.value)} multiple={false}>
                                    <option value=""></option>
                                    {areaUseData?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                                </select>
                            </div>
                            <div className={styles.divProduct}>
                                <TextRequest label='Em qual atividade será utilizado o produto ?' />
                                <input className={styles.inputProduct} onChange={(e) => setActivityUsed(e.target.value)} value={activityUsed} />
                            </div>

                        </div>
                    </div>
                    <div className={styles.containerAnexos}>
                        <div className={styles.mainAnexos}>
                            <TextPages title='Anexos' />
                            <div className={styles.divAnexos}>
                                <UploadFile
                                    label={'FISPQ'}
                                    selectedFile={selectedFileFISPQ}
                                    setSelectedFile={setSelectedFileFISPQ}
                                    anexos={FISPQ}
                                    setAnexos={setFISPQ}
                                    addedFiles={addedFilesFISPQ}
                                    setAddedFiles={setAddedFilesFISPQ}
                                    valueFISPQ={valueFISPQ}
                                    setValueFISPQ={setValueFISPQ}
                                    setDateFISPQ={setDateFISPQ}
                                    dateFISPQ={dateFISPQ}
                                    setRemoveAnexo={setRemoveAnexo}
                                    removeAnexo={removeAnexo}                               
                                />
                                <UploadFile
                                    label={'Ficha de emergência'}
                                    selectedFile={selectedFileFicha}
                                    setSelectedFile={setSelectedFileFicha}
                                    anexos={ficha}
                                    setAnexos={setFicha}
                                    addedFiles={addedFilesFicha}
                                    setAddedFiles={setAddedFilesFicha}
                                    setDateFISPQ={setDateFISPQ}
                                    dateFISPQ={dateFISPQ}
                                    setRemoveAnexo={setRemoveAnexo}
                                    removeAnexo={removeAnexo}   
                                    valueFISPQ={valueFISPQ}
                                    setValueFISPQ={setValueFISPQ} 
                                />
                                <UploadFile
                                    label={'Demais anexos'}
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    anexos={anexos}
                                    setAnexos={setAnexos}
                                    addedFiles={addedFiles}
                                    setAddedFiles={setAddedFiles}
                                    setDateFISPQ={setDateFISPQ}
                                    dateFISPQ={dateFISPQ}
                                    setRemoveAnexo={setRemoveAnexo}
                                    removeAnexo={removeAnexo}  
                                    valueFISPQ={valueFISPQ}
                                    setValueFISPQ={setValueFISPQ}  
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.divButton}>
                        <button onClick={() => handleCancelEdit()}>Cancelar</button>
                        <button onClick={() => handleSubmitEdit()}>Submeter edição</button>
                    </div>
                    </div>}
                </div>
            </div>
            <ModalReplySites id={id} sites={product.siteUse} />
            <ModalLogsAprovacao id={id} open={openModalLogAprovacoes} setOpen={setOpenModalLogAprovacoes}  />
        </>
    )
}