import React from "react";
import Rotas from './routes';

import './styles/global.css'
import "rsuite/dist/rsuite.min.css";

export default function App(){
  return(
    <Rotas/>
  )
}