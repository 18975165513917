import React from "react";
import style from "./title.module.css";

export default function TitlePages(props) {
  return (
    <>
      <div className={style.titlePages}>
        <img alt='icon' src={props.icon} />
        <h3 style={props.styles}>{props.title}</h3>
      </div>
    </>
  )
}