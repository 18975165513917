import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from 'react-modal';
import Table from 'rc-table';
import moment from 'moment'

import { useProduct } from '../../providers/product'
import { RiCloseFill } from 'react-icons/ri'
import { api } from "../../services/api";

import iconApprovals from '../../assets/icon_approvalsModal.svg'
import backgroundModal from "../../assets/icon_backgroundModal.png";

import styles from "./styles.module.css";
export default function ModalLogsAprovacao(props) {

    const [loading, setLoading] = useState([])
    const [approvals, setApprovals] = useState([])

    function handleCloseModal() {
        props.setOpen(false)
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const logsApprvals = await api.get(`/logs/approvals/products/${props.id}`)
            setApprovals(logsApprvals.data)
            setLoading(false)
        }
        fetchData();
    }, []);

    const columns = [
        {
            title: "Data",
            field: "evaluationDate",
            align: "left",
            render: (val, obj) => moment(val.evaluationDate).format('DD-MM-YYYY')

        },
        {
            title: "Aprovador",
            align: "left",
            render: (val, obj) => val.Users?.name

        },
        {
            title: "Area",
            dataIndex: "checklistType",
            align: "left",
        },
        {
            title: "Resposta",
            dataIndex: "evaluation",
            align: "left",
        },
        {
            title: "Justificativa",
            dataIndex: "justification",
            align: "left",
        },
        {
            title: "Recomendações",
            dataIndex: "recommendations",
            align: "left",
        },
        
    ];

    return (
        <>
            <Modal isOpen={props.open} onRequestClose={handleCloseModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundImage: `url(${backgroundModal})`,
                        backgroundSize: 'cover',
                        backgroundColor:' transparent  !important',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-14%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        height:' 70%',
                        padding:'0px',
                        borderRadius:'13px',
                    }
                }}>
                <div className={styles.main}>
                    <div className={styles.title}>
                        <div style={{display:'flex', alignItems:'center'}}><img alt='approvals' src={iconApprovals} /> <h1>Minhas aprovações</h1></div>
                        <h2 onClick={handleCloseModal} style={{ fontSize: '150%', cursor: 'pointer' }}><RiCloseFill color="#fff"/></h2>
                    </div>
                    <div className={styles.table}>
                        <Table
                            sticky={true}
                            showHeader={true}
                            columns={columns}
                            data={approvals}
                            rowKey='id'
                            emptyText="Nenhum log aprovação!"
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}