import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from 'react-modal';
import { FaMountain } from 'react-icons/fa'
import Swal from "sweetalert2";


import { useProduct } from '../../providers/product'
import { RiCloseFill } from 'react-icons/ri'
import { api } from "../../services/api";

import iconSolitacoes from '../../assets/icon_solitacoesModal.svg'
import backgroundModal from "../../assets/icon_backgroundModal.png";

import styles from "./styles.module.css";

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    width: 400,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default function ModalSites(props) {

    const { isOpenModalSites, setIsOpenModalSites } = useProduct()
    const [sites, setSites] = useState([])
    const [selectSites, setSelectSites] = useState('')

    const [isAdmin, setIsAdmin] = useState('')
    const [isApprover, setIsApprover] = useState('')

    const navigate = useNavigate()

    function handleCloseModal() {
        setIsOpenModalSites(false)
    }

    useEffect(() => {
        async function fetchData() {
            let response = await api.get('identify')
            setIsAdmin(response.data.isAdmin)
            setIsApprover(response.data.isApproval)
            
            let responseSites = await api.get('/sites')
            setSites(responseSites.data)
        }
        fetchData();
    }, []);


    function openSites() {
        setIsOpenModalSites(false)
    }

    function handleSites() {
        if(selectSites !== 'vazio'){
            setIsOpenModalSites(false)
            sessionStorage.setItem('site', selectSites);
        }else{
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um site antes de submeter.`
            })
        }
    }

    return (
        <>
            <Modal isOpen={isOpenModalSites} onRequestClose={handleCloseModal}
                ariaHideApp={false} shouldCloseOnEsc={false}  shouldCloseOnOverlayClick={false}
                style={{
                    overlay: {
                        backgroundImage: `url(${backgroundModal})`,
                        backgroundSize: 'cover',
                        backgroundColor: ' transparent  !important',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-14%',
                        transform: 'translate(-50%, -50%)',
                        width: '35%',
                        height:' 35%',
                        overflow:'hidden',
                        padding: '0px',
                        borderRadius: '13px',
                    }
                }}>
                <div className={styles.main}>
                    <div className={styles.title}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem'}}><FaMountain color="#fff" style={{ marginRight: '5px', }} /> <h1>Selecione um site</h1></div>
                    </div>
                    <div className={styles.table}>
                        <select className={styles.select} value={selectSites} multiple={false} onChange={(e) => { setSelectSites(e.target.value) }}>
                        {isAdmin == true || isApprover == true ?
                            <option value="">Todos</option>:
                            <option  value="">Selecione</option>}
                            {sites?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                        </select>
                            <button className={styles.button} onClick={() => handleSites()}>Enviar</button>
                    </div>

                </div>
            </Modal>
        </>
    )
}