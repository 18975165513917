import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FaMountain,FaUsersCog } from 'react-icons/fa'
import { HiUsers } from 'react-icons/hi'
import { MdPlace, MdPieChart } from 'react-icons/md'
import { GoPerson } from 'react-icons/go'
import { RiUserSettingsLine } from 'react-icons/ri'
import icon_settings from '../../assets/icon_settings.svg'

import Header from "../../components/header";
import Adms from "./adms";
import Approvals from "./approvers";
import ProductGrup from "./grupProduct";
import AreasUse from "./areas";
import Sites from "./sites";
import UsersActives from "./usersActives";

import style from './adm.module.css'

export default function HomeAdm() {

    const [selectedItem, setSelectedItem] = useState('administradores')
    const navigate = useNavigate()

    return (
        <>
            <Header funcao={() => navigate('/home')} />
            <div className={style.main}>
                <div className={style.rightMenu}>
                    <div className={style.titleMenu}>
                      <img style={{width: '2rem'}} src={icon_settings}/>  <p>Painel Administrativo</p>
                    </div>
                    <div onClick={() => setSelectedItem('administradores')} className={selectedItem == 'administradores' ?
                        `${style.itemMenu} ${style.selectedItemMenu}` : `${style.itemMenu}`}> <GoPerson size={18}/> Administradores</div>
                    <div onClick={() => setSelectedItem('areas')} className={selectedItem == 'areas' ?
                        `${style.itemMenu} ${style.selectedItemMenu}` : `${style.itemMenu}`}> <MdPlace size={18}/> Áreas</div>
                    <div onClick={() => setSelectedItem('sites')} className={selectedItem == 'sites' ?
                        `${style.itemMenu} ${style.selectedItemMenu}` : `${style.itemMenu}`}> <FaMountain size={18}/> Sites</div>
                    <div onClick={() => setSelectedItem('grupo')} className={selectedItem == 'grupo' ?
                        `${style.itemMenu} ${style.selectedItemMenu}` : `${style.itemMenu}`}> <MdPieChart size={18}/> Grupo de produtos</div>
                    <div onClick={() => setSelectedItem('aprovadores')} className={selectedItem == 'aprovadores' ?
                        `${style.itemMenu} ${style.selectedItemMenu}` : `${style.itemMenu}`}> <HiUsers size={18}/> Aprovadores</div>
                    <div onClick={() => setSelectedItem('usuario')} className={selectedItem == 'usuario' ?
                        `${style.itemMenu} ${style.selectedItemMenu}` : `${style.itemMenu}`}> <FaUsersCog size={18}/> Usuários Inativos</div>
                    {/* <div onClick={() => setSelectedItem('relatorios')} className={selectedItem == 'relatorios' ?
                        `${style.itemMenu} ${style.selectedItemMenu}` : `${style.itemMenu}`}> <BsFileBarGraphFill /> Relatórios</div> */}
                </div>

                <div>
                    {selectedItem == 'administradores' ?
                        <Adms />
                        : selectedItem == 'aprovadores' ?
                            <Approvals />
                            : selectedItem == 'areas' ?
                                <AreasUse />
                                : selectedItem == 'grupo' ?
                                    <ProductGrup />
                                    : selectedItem == 'sites' ?
                                        <Sites />
                                        : selectedItem == 'usuario' ?
                                        <UsersActives />
                                        : null
                    }
                </div>
            </div>
        </>
    )
}