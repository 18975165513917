import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import moment from 'moment'
import { IoAttachOutline } from "react-icons/io5";

import { api } from "../../services/api";
import { useProduct } from "../../providers/product"
import iconSearch from '../../assets/icon_search.svg'
import TextPages from "../../components/textPages";
import Spinner from '../../components/spinner';

import Header from "../../components/header";
import TitlePages from '../../components/titlePages'
import ButtonAnexos from "../../components/buttonAnexos";
import TextRequest from "../../components/textRequest";

import style from './approvals.module.css'
import ModalChecklist from "../../components/modalChecklist";
import ViewUpload from "../../components/viewUpload";

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    width: 400,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
export default function MyApprovals() {

    const navigate = useNavigate()
    const location = useLocation()
    const id = location.state.id
    console.log(id)
    const checklistType = location.state.checklistType

    const [product, setProduct] = useState([])
    const {  setOpenModalChecklist} = useProduct()
    const [FISPQ, setFISPQ] = useState([])
    const [ficha, setFicha] = useState([])
    const [anexos, setAnexos] = useState([])
    const [loading, setLoading] = useState([])


    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            let response = await api.get(`/product/${id}`)
            setProduct(response.data)
            let responseAttachments = await api.get(`/attachments/products/${id}`)
            let FISPQ = []
            let ficha = []
            let anexo = []
            responseAttachments.data?.forEach(attachment => {

                if (attachment.typeAttachments == 'FISPQ') {
                    FISPQ.push(attachment)
                } else if (attachment.typeAttachments == 'ficha') {
                    ficha.push(attachment)
                } else {
                    anexo.push(attachment)
                }
            })
            setFISPQ(FISPQ)
            setFicha(ficha)
            setAnexos(anexo)
            setLoading(false)
        }
        fetchData();
    },[id]);


    function handleSubmit() {
        setOpenModalChecklist(true)
    }

    return (
        <>
        {loading == true ? (
                <div className="spinner">
                    <Spinner animation="border" />
                </div>
            ) : (<div></div>)}
            <Header funcao={() => navigate('/home')} />
            <div className={style.background}>
                <div className={style.main}>
                    <TitlePages icon={iconSearch} styles={{ fontSize: '1.2rem' }} title='Aprovação de Produto' />
                    <div className={style.container}>
                        <div className={style.title}>
                            <h2>Informações Gerais</h2>
                        </div>
                        <div className={style.mainProduct}>
                            <div className={style.divProduct}>
                                <TextRequest label={'Solicitante :'} />
                                <span className={style.nameProduct}>{product.Users?.name}</span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Cargo :' />
                                <span className={style.nameProduct}>{product.responsibility}</span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Grupo de produto :' />
                                <span className={style.nameProduct}>{product.ProductGroup?.name} </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Site solicitante :' />
                                <span className={style.nameProduct}>{product.requestingSite?.name}</span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Princípio Ativo :' info='Consultar informação na FISPQ' />
                                <span className={style.nameProduct}>{product.activePrinciple} </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Data Solicitação :' />
                                <span className={style.nameProduct}>{moment(product.requestDate).format('DD-MM-YYYY')} </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Ação do produto :' />
                                <span className={style.nameProduct}>{product.productAction} </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Nome do Produto:' />
                                <span className={style.nameProduct}>{product.productName} </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Controlado por PF/FA/PC ?' info='Polícia Federal, Forças Armadas e Polícia Civil'/>
                                <span className={style.nameProduct}>{product.controlledPFFAPC === true ? 'Sim' : 'Não'} </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Fabricante :' />
                                <span className={style.nameProduct}>{product.manufacturer} </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Necessita de aprovação da equipe de Qualidade ?' />
                                <span className={style.nameProduct}>{product.qualityApproval === true ? 'Sim' : 'Não'} </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Produto é RET ?' info='Produto é RET' />
                                <span className={style.nameProduct}>{product.RETProduct === true ? 'Sim' : 'Não'} </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Sites para utilização :' />
                                <span className={style.nameProduct}>{product.siteUse} </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Áreas de utilização :' />
                                <span className={style.nameProduct}>{product.AreasUse?.name } </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Em qual atividade será utilizado o produto ?' />
                                <span className={style.nameProduct}>{product.activityProductUsed }  </span>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Foi aberto um MOC ?' />
                                <span className={style.nameProduct}>{product.MOCOpened == true ? 'Sim' : 'Não'} </span>

                            </div>
                        </div>
                    </div>
                    <div className={style.containerAnexos}>
                        <div className={style.mainAnexos}>
                            <TextPages title='Anexos' />
                            <div className={style.DivAnexos}>
                                <div className={style.anexos}>
                                    <ButtonAnexos icon={<IoAttachOutline size={25} color='#fff' />} title='FISPQ' />
                                    <ViewUpload anexo={FISPQ} FISPQ={product.FISPQDueDate} name='FISPQ' />
                                </div>
                                <div className={style.anexos}>
                                    <ButtonAnexos icon={<IoAttachOutline size={25} color='#fff' />} title='Ficha de Emergência' />
                                    <ViewUpload anexo={ficha} name='Ficha de Emergência' />
                                </div>
                                <div className={style.anexos}>
                                    <ButtonAnexos icon={<IoAttachOutline size={25} color='#fff' />} title='Demais anexos' />
                                    <ViewUpload anexo={anexos} name='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.divButton}>
                        <button onClick={() => handleSubmit()}>Responder Checklist</button>
                    </div>
                    <ModalChecklist id={id} checklistType={checklistType} />
                </div>
            </div>
        </>
    )
}