import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from 'react-modal';
import Table from 'rc-table';
import moment from 'moment'

import { useProduct } from '../../providers/product'
import { RiCloseFill } from 'react-icons/ri'
import { api } from "../../services/api";

import iconApprovals from '../../assets/icon_approvalsModal.svg'
import backgroundModal from "../../assets/icon_backgroundModal.png";

import styles from "./styles.module.css";
export default function ModalAprovacao(props) {

    const { openModalAprovacoes, setOpenModalAprovacoes} = useProduct()
    const [products, setProducts] = useState([])

    const navigate = useNavigate()

    function handleCloseModal() {
        setOpenModalAprovacoes(false)
    }

    const siteId = sessionStorage.getItem('site')
    useEffect(() => {
        async function fetchData() {
            if (siteId !== null && siteId !== '') {
                let response = await api.get(`/site/approvals/products/${siteId}`)
                setProducts(response.data)
            } else {
                let response = await api.get(`/approvals/products`)
                setProducts(response.data)
            }
        }
    
        fetchData();
    }, [siteId]);

    const columns = [
        {
            title: "Data da solicitação",
            field: "requestDate",
            align: "left",
            render: (val, obj) => moment(val.requestDate,).format('DD-MM-YYYY')

        },
        {
            title: "Produto",
            dataIndex: "productName",
            align: "left",

        },
        {
            title: "Site",
            align: "left",
            render: (val, obj) => val.requestingSite?.name

        },
        {
            title: "Status",
            dataIndex: "status",
            align: "left",
        },
        {
            title: "Aprovação",
            dataIndex: "checklistType",
            align: "left",
        },
    ];

    function onRowClick(record, index) {
        console.log(record)
        setOpenModalAprovacoes(false)
        navigate('/approvals-product', { state: { id: record.id , checklistType:record.checklistType } })
    }

    return (
        <>
            <Modal isOpen={openModalAprovacoes} onRequestClose={handleCloseModal}
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundImage: `url(${backgroundModal})`,
                        backgroundSize: 'cover',
                        backgroundColor:' transparent  !important',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-14%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        height:' 70%',
                        padding:'0px',
                        borderRadius:'13px',
                    }
                }}>
                <div className={styles.main}>
                    <div className={styles.title}>
                        <div style={{display:'flex', alignItems:'center'}}><img alt='approvals' src={iconApprovals} /> <h1>Minhas aprovações</h1></div>
                        <h2 onClick={handleCloseModal} style={{ fontSize: '150%', cursor: 'pointer' }}><RiCloseFill color="#fff"/></h2>
                    </div>
                    <div className={styles.table}>
                        <Table
                            sticky={true}
                            showHeader={true}
                            columns={columns}
                            data={products}
                            onRow={(record, index, row) => ({
                                onClick: onRowClick.bind(null, record, index)
                            })}
                            rowKey='id'
                            emptyText="Nenhum produto para aprovação!"
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}