import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from 'moment'
import { useNavigate } from "react-router-dom";

import { api } from "../../services/api";

import { RiCloseFill } from 'react-icons/ri'
import iconApprovals from '../../assets/icon_approvalsModal.svg'
import { useProduct } from '../../providers/product'
import Spinner from '../spinner';

import styles from "./styles.module.css";


const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    width: 400,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
export default function QuestionsSegurança(props) {
    const navigate = useNavigate()

    const date = new Date();
    const futureDate = date.getDate()
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    const [evaluationDate, setEvaluationDate] = useState(defaultValue)
    const [storageLocation, setStorageLocation] = useState([])
    const [EPIs, setEPIs] = useState([])
    const [apropriatedLocal, setApropriatedLocal] = useState([])
    const [evaluatorName, setEvaluatorName] = useState(props.name)
    const [evaluatorId, setEvaluatorId] = useState(props.id)
    const [productId, setProductId] = useState(props.productId)

    const [evaluation, setEvaluation] = useState([])
    const [recommendations, setRecommendations] = useState('')
    const [justification, setJustification] = useState('')
    const [restriction, setRestriction] = useState('')

    const [loading, setLoading] = useState([])

    const { setOpenModalChecklist } = useProduct()

    function handleCloseModal() {
        setOpenModalChecklist(false)
    }
    let validated = []

    function validation() {
        validated = true
        if (storageLocation == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir o site onde o produto será manipulado/armazenado antes de submeter.`
            })
            validated = false
            return false
        }
        if (evaluationDate == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir uma data da avaliação antes de submeter.`
            })
            validated = false
            return false
        }
        if (EPIs == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir se existem EPIs adequados para o manuseio seguro do produto antes de submeter.`
            })
            validated = false
            return false
        }
        if (apropriatedLocal == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir se existe local apropriado para o armazenamento, antes de submeter.`
            })
            validated = false
            return false
        }

        if (evaluation == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir uma avaliação antes de submeter.`
            })
            validated = false
            return false
        }
        if (evaluation == 'Reprovado' && justification == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir uma justificativa antes de submeter.`
            })
            validated = false
            return false
        }
        if (evaluation == 'Aprovado com restrições' && restriction == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir as restrições antes de submeter.`
            })
            validated = false
            return false
        }
        if (recommendations == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir as recomendações antes de submeter.`
            })
            validated = false
            return false
        }
        if (props.productId == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir id do produto ão antes de submeter.`
            })
            validated = false
            return false
        }
        if (props.id == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir id antes de submeter.`
            })
            validated = false
            return false
        }
    }
    async function handleSubmit() {
        validation();
        if (validated == false) {
            return false
        }
        try {
            setLoading(true)
            let response = await api.post(`security/checklist`, {
                "createdBy": evaluatorId,
                "evaluationDate": moment(evaluationDate).format('YYYY-MM-DD'),
                "storageLocation": storageLocation,
                "EPIs": EPIs,
                "apropriatedLocal": apropriatedLocal,
                "evaluation": evaluation,
                "productId": productId,
                "justification": justification,
                "restriction": restriction,
                "recommendations": recommendations,
            })

            if (response.status == 200) {
                Swal.fire({
                    title: 'Avaliação enviada !',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#009EBE',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/products')
                        setOpenModalChecklist(false)
                    }
                })

            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data}`
            })
        }
        setLoading(false)
    }

    return (
        <>
            {loading == true ? (
                <div className="spinner">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div></div>)}
            <div className={styles.main}>
                <div className={styles.title}>
                    <div style={{ display: 'flex', alignItems: 'center' }}><img alt='approvals' src={iconApprovals} /> <h1>Avaliação Meio Ambiente / Segurança</h1></div>
                    <h2 onClick={handleCloseModal} style={{ fontSize: '150%', cursor: 'pointer' }}><RiCloseFill color="#fff"/></h2>
                </div>
                <div className={styles.mainAvaliação}>
                    <div className={styles.questions}>
                        <div style={{ display: 'flex' }} className={styles.divQuestions}>
                            <div style={{ width: '80%', marginRight: '0.8rem' }}>
                                <h2>Nome do avaliador:</h2>
                                <input style={{ width: '95%' }} disabled type='text' value={evaluatorName} onChange={(e) => setEvaluatorName(e.target.value)} />
                            </div>
                            <div style={{ width: '31%' }}>
                                <h2>Data da avaliação:</h2>
                                <input disabled type='date' value={evaluationDate} onChange={(e) => setEvaluationDate(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.divQuestions}>
                            <h2>Site onde o produto será manipulado/armazenado:</h2>
                            <input style={{ width: '100%' }} type="text" value={storageLocation} onChange={(e) => setStorageLocation(e.target.value)} />
                        </div>
                        <div className={styles.divQuestions}>
                            <h2>Existe local apropriado para o armazenamento, considerando aeração, contenção e inflamabilidade ?</h2>
                            <input style={{ width: '100%' }} type="text" value={apropriatedLocal} onChange={(e) => setApropriatedLocal(e.target.value)} />
                        </div>
                        <div className={styles.divQuestions}>
                            <h2>Existem EPIs adequados para o manuseio seguro do produto ?</h2>
                            <input style={{ width: '100%' }} type="text" value={EPIs} onChange={(e) => setEPIs(e.target.value)} />
                        </div>
                        <div className={styles.divQuestions}>
                            <h2>Recomendações:</h2>
                            <input type='text' value={recommendations} onChange={(e) => setRecommendations(e.target.value)} />
                        </div>
                    </div>
                    <hr />
                    <div className={styles.answers}>
                        <h1>Avaliação </h1>
                        <div className={styles.containerAnswers}>
                            <div className={styles.divAnswers}>
                                <input value="Aprovado" onChange={(e) => setEvaluation(e.target.value)} type="radio" id="aprovado" name="Avaliação" />
                                <label for="aprovado">Aprovado</label>
                            </div>
                            <div style={{ flexDirection: 'column' }} className={styles.divAnswers}>
                                <div style={{ display: 'flex' }}>
                                    <input value="Reprovado" onChange={(e) => setEvaluation(e.target.value)} type="radio" id="reprovado" name="Avaliação" />
                                    <label for="reprovado">Reprovado</label>
                                </div>
                                {evaluation == 'Reprovado' ?
                                    <div style={{ marginTop: '0.5rem' }}>
                                        <textarea onChange={(e) => setJustification(e.target.value)} style={{ height: '60px' }} placeholder="Justificativa" />
                                    </div>
                                    : null}
                            </div>
                            <div style={{ flexDirection: 'column' }} className={styles.divAnswers}>
                                <div style={{ display: 'flex' }}>
                                    <input value="Aprovado com restrições" onChange={(e) => setEvaluation(e.target.value)} type="radio" id="aprovadoCondições" name="Avaliação" />
                                    <label for="aprovadoCondições">Aprovado com restrições</label>
                                </div>
                                {evaluation == 'Aprovado com restrições' ?
                                    <div style={{ marginTop: '0.5rem' }}>
                                        <textarea onChange={(e) => setRestriction(e.target.value)} style={{ height: '60px' }} placeholder="Restrições" />
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.divButton}>
                    <button onClick={() => handleSubmit()}>Submeter</button>
                </div>
            </div>
        </>
    )
}