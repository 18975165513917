import React from "react";
import style from "./text.module.css";

import { AiFillQuestionCircle } from 'react-icons/ai'
import {LightTooltip} from '../tooltip/index'

export default function TextRequest(props) {
  return (
    <>
      <div className={style.textProduct}>
        <label>{props.label}
        {props.icon == true ?
          <LightTooltip  placement="right" style={{display: 'flex'}}  title={props.info}>
           <p><AiFillQuestionCircle className={style.iconImage} color="#010066"/>    </p>   
          </LightTooltip>: null}
        </label>
      </div>
    </>
  )
}