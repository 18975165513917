import React from "react";

import Style from "./labelForms.module.css";


export default function LabelForms(props) {

    return (
      <label className={Style.labelForms} {...props}></label>    
    )
  }