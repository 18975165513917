import React, { useEffect } from "react";

import { IoAttachOutline } from "react-icons/io5";
import { FaTimes } from 'react-icons/fa'
import { AiOutlinePlusSquare, AiOutlinePaperClip, AiOutlineFileImage, AiOutlineFileExcel, AiOutlineFilePdf } from 'react-icons/ai'
import { Tooltip } from '@mui/material';
import Swal from "sweetalert2";

import style from './file.module.css'

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    width: 400,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default function UploadFile(props) {
    const hiddenFileInputFiSPQ = React.useRef(null);
    function handleClickFISPQ() {
        hiddenFileInputFiSPQ.current.click();
    };

    useEffect(() => {
        let anexo = []
        props.addedFiles?.forEach(attachment => {
            anexo.push(attachment)
        })
        props.setAnexos(anexo)
    }, [props.addedFiles])


    function handleChangeFISPQ(event) {
        const fileUploaded = event.target.files[0];
        event.target.value = ''
        props.setSelectedFile(fileUploaded)
    }

    function handleAddFile() {
        if(props.label == 'FISPQ'){            
            if (props.selectedFile !== null && props.valueFISPQ) {
                    const file = []
                    file.push({
                        lastModified: props.selectedFile.lastModified,
                        lastModifiedDate: props.selectedFile.lastModifiedDate,
                        name: props.selectedFile.name,
                        size: props.selectedFile.size,
                        type:props.selectedFile.type,
                        webkitRelativePath: props.selectedFile.webkitRelativePath,
                        validUntil:props.valueFISPQ
                    })
                    if (props.addedFiles.filter(x => x.name === props.selectedFile.name).length > 0) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Arquivo já adicionado!'
                        })
                        return
                    }else {
                        props.setAddedFiles([...props.addedFiles, props.selectedFile])
                        props.setDateFISPQ([...props.dateFISPQ, ...file])
                        props.setSelectedFile(null)
                        props.setValueFISPQ(null)
                    }
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Inclusão inválida! É necessário inserir um anexo antes de submeter.'
                    })
                }
        }else if(props.label == 'Ficha de emergência'){
         
                if (props.selectedFile !== null) {
                    const file = props.selectedFile
                    if (props.addedFiles.filter(x => x.name === props.selectedFile.name).length > 0) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Arquivo já adicionado!'
                        })
                        return
                    } else {
                        props.setAddedFiles([...props.addedFiles, file])
                        props.setSelectedFile(null)
                    }
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Inclusão inválida! É necessário inserir um anexo antes de submeter.'
                    })
                }
        } else if(props.label == 'Demais anexos'){
             if (props.selectedFile !== null) {
                 const file = props.selectedFile
                 if (props.addedFiles.filter(x => x.name === props.selectedFile.name).length > 0) {
                     Toast.fire({
                         icon: 'error',
                         title: 'Arquivo já adicionado!'
                     })
                     return
                 } else {
                     props.setAddedFiles([...props.addedFiles, file])
                     props.setSelectedFile(null)
                 }
             } else {
                 Toast.fire({
                     icon: 'error',
                     title: 'Inclusão inválida! É necessário inserir um anexo antes de submeter.'
                 })
             }
        }
    }

    function removeFile(el) {
        let newAddedFiles = props.addedFiles.filter(x => x.name !== el.name)
        console.log(props.addedFiles)

        props.setAddedFiles(newAddedFiles)
        let newAddedFile = props.dateFISPQ.filter(x => x.name !== el.name)
        props.setDateFISPQ(newAddedFile)
        props.setSelectedFile(undefined)
        props.setValueFISPQ(null)
       
        console.log(el)

        if(el.id){
           const remove = props.addedFiles.filter(x => x.name == el.name)
           console.log(remove)
           props.setRemoveAnexo([...props.removeAnexo, ...remove])
        }
    }

    return (
        <div className={style.uploadDiv}>
            <div className={style.divInput} >
                <IoAttachOutline size={25} color='#fff' />
                <label>{props.label}</label>
            </div>
            <div className={style.uploadInput}>
                <button className={style.buttonInput} onClick={() => handleClickFISPQ()}>
                    <span>{props.selectedFile === null || props.selectedFile === ''|| props.selectedFile?.length == 0 ||props.selectedFile === undefined ? "Adicionar anexos" : props.selectedFile.name}</span>
                </button>
                {props.selectedFile?
                <>
                {props.label == 'FISPQ' ? 
                    <div className={style.inputContainer}>
                        <input value={props.valueFISPQ} onChange={(e) => props.setValueFISPQ(e.target.value)} type='date'/> 
                    </div> : null}
                </>
                : null}
                <div style={{display: 'flex', width: '18%',gap: '0.5rem', justifyContent: 'end'}}>
                    <AiOutlinePaperClip color="#009EBE" onClick={() => handleClickFISPQ()} size={25} />
                    <AiOutlinePlusSquare onClick={() => handleAddFile()} color="#009EBE" size={25} />
                </div>
            </div>
            <input
                type="file"
                accept="application/pdf,.csv,image/png,image/jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ref={hiddenFileInputFiSPQ}
                onChange={(event) => handleChangeFISPQ(event)}
                style={{ display: 'none' }}
            />
            <div className={style.imgDiv}>
                {props.anexos?.length !== 0 ?
                    <div className={style.imgDiv}>
                        {props.anexos?.map(x => {
                            return (
                                <div key={x.name} className={style.imgContainer}>
                                    {x.type?.split('/')[1] == 'pdf' ?
                                        <div className={style.anexosDiv} >
                                            <Tooltip title={x.name} placement="top">
                                                <button>
                                                    <AiOutlineFilePdf color='#000' size={70} />
                                                </button>
                                            </Tooltip>
                                            <div onClick={() => removeFile(x)} className={style.deleteImgIcon}>
                                                 <FaTimes />
                                            </div>
                                        </div>
                                        : x.type?.split('/')[1] == 'csv' ?
                                            <>
                                                <div className={style.anexosDiv}>
                                                    <Tooltip title={x.name} placement="top" >
                                                        <button>
                                                            <AiOutlineFileExcel color='#000' size={70} />
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                                <div onClick={() => removeFile(x)} className={style.deleteImgIcon}>
                                                    <FaTimes />
                                                </div>
                                            </> :
                                            <>
                                            {
                                                x.link ?
                                                <>
                                                    <img className={style.img} alt='' title={x.name} src={x.link} />
                                                    <div onClick={() => removeFile(x)} className={style.deleteImgIcon}>
                                                        <FaTimes />
                                                    </div>
                                                </>:
                                                 <>
                                                    <img className={style.img} alt='' title={x.name} src={URL.createObjectURL(x)} />
                                                    <div onClick={() => removeFile(x)} className={style.deleteImgIcon}>
                                                        <FaTimes />
                                                    </div>
                                                 </>
                                             }
                                        </>
                                    }
                                    {props.label == 'FISPQ' ?
                                    <div className={style.inputContainer}>
                                        <label>Vencimento:</label>
                                        <input type='date' disabled  value={props.dateFISPQ?.find(e=> e.name == x.name)?.validUntil}/>
                                    </div>:null }
                                </div>
                            )
                        })
                    }
                </div> : null}
            </div>
        </div>
    )
}