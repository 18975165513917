import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';

import { CgProfile } from 'react-icons/cg'
import { AiOutlineLine } from 'react-icons/ai'
import { IoIosArrowBack } from 'react-icons/io'
import logo from "../../assets/icon_syngenta.svg"
import iconApprovals from '../../assets/icon_approvals.svg'
import iconHome from '../../assets/icon_home.svg'

import { LightTooltip } from '../tooltip/index'
import ModalAprovações from "../modalAprovacoes";
import { useProduct } from '../../providers/product'
import { api } from '../../services/api'

import style from "./header.module.css"

export default function Header(props) {
    const { setOpenModalAprovacoes } = useProduct()
    const [isAdmin, setIsAdmin] = useState('')
    const [isApprover, setIsApprover] = useState('')
    const [name, setName] = useState('')
    const [loginMenu, setLoginMenu] = useState(false)

    function Logout() {
        Cookie.remove('token');
        sessionStorage.clear()
        window.location.href = "/";
    }

    useEffect(() => {
        async function getIdentify() {
            const approver = sessionStorage.getItem('isApprover');
            const admin = sessionStorage.getItem('isAdmin');
            const isName = sessionStorage.getItem('name');
            setName(isName)
            setIsAdmin(admin)
            setIsApprover(approver)

        }
        getIdentify();
    });

    return (
        <div className={style.containerHeader}>
            <div className={style.logo}>
                <IoIosArrowBack onClick={props.funcao} color='#fff' size={25} />
                <Link to='/home'><img alt='logo' src={logo} /></Link>
            </div>
            <div className={style.infoUser}>

                {isAdmin || isApprover ?
                    <div style={{ justifyContent: 'flex-end' }} className={style.infos} >
                        <LightTooltip title="Minhas Aprovações">
                            <button onClick={() => setOpenModalAprovacoes(true)}><img alt='approvals' src={iconApprovals} /></button>
                        </LightTooltip>
                        <AiOutlineLine color='#fff' size={40} style={{ transform: "rotate(90deg)", marginLeft: '-0.5rem' }} />
                    </div> :
                    null}
                <div className={style.infos}>
                    <LightTooltip title="Home">
                        <Link to='/home'><img alt='home' src={iconHome} /></Link>
                    </LightTooltip>
                </div>
                <div className={style.logout} >
                    <CgProfile color='#ffff' size={35} onClick={() => setLoginMenu(c => !c)} />
                    {loginMenu == true ?
                        <button className={style.logoutButton} onClick={() => Logout()} >Logout</button> :
                        null
                    }
                </div>
                <div className={style.nameFunction}>
                    <span><strong>{name}</strong></span>
                    <span>{isAdmin == 'true' ?
                        'Administrador' :
                        <>
                            {isApprover == 'true' ?
                                'Aprovador'
                                : 'Usuário padrão'}
                        </>
                    }</span>
                </div>
            </div>
            <ModalAprovações />
        </div>
    )
}