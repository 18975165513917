import React, { useEffect, useState } from "react";
import { useNavigate, useLocation} from "react-router-dom";
import Swal from "sweetalert2";

import { api, addAttachment} from "../../services/api";
import { useProduct } from "../../providers/product"
import { IoAttachOutline } from "react-icons/io5";
import iconSearch from '../../assets/icon_search.svg'
import TextPages from "../../components/textPages";

import Header from "../../components/header";
import TitlePages from '../../components/titlePages'
import ButtonAnexos from "../../components/buttonAnexos";
import TextRequest from "../../components/textRequest";
import UploadFile from "../../components/UploadFile";
import Spinner from '../../components/spinner';
import moment from 'moment'

import style from './requestProduct.module.css'

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    width: 400,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
export default function SaveProducts() {
    const location = useLocation()
    const productId = location.state.id

    const navigate = useNavigate()
    //SOLICITAÇÃO DE PRODUTO
    const date = new Date();
    const futureDate = date.getDate()
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    const [evaluationDate, setEvaluationDate] = useState(defaultValue)


    const [ responsibility, setResponsibility ] = useState()
    const [ productGrup, setProductGrup ] = useState()
    const [ site, setSite ] = useState()
    const [ activePrinciple, setActivePrinciple ] = useState()
    const [ requestDate, setRequestDate ] = useState(defaultValue)
    const [ productAction, setProductAction ] = useState()
    const [ productName, setProductName ] = useState()
    const [ controlledPFFAPC, setControlledPFFAPC ] = useState()
    const [ manufacturer, setManufacturer ] = useState()
    const [ qualityApproval, setQualityApproval ] = useState()
    const [ RETProduct, setRETProduct ] = useState()
    const [ siteUse, setSiteUse ] = useState()
    const [ areasUse, setAreasUse ] = useState()
    const [ activityUsed, setActivityUsed ] = useState()
    const [ isMoc, setIsMoc ] = useState()

    //FISPQ
    const [ saveFISPQ, setSaveFISPQ ] = useState([])
    //ficha
    const [ saveFicha, setSaveFicha ] = useState([])
    //demais anexos
    const [ saveAnexos, setSaveAnexos ] = useState()


    //FISPQ
    const { FISPQ, setFISPQ } = useProduct([])
    const { anexosFISPQ, setAnexosFISPQ } = useProduct()
    const { addedFilesFISPQ, setAddedFilesFISPQ } = useProduct()
    const { selectedFileFISPQ, setSelectedFileFISPQ } = useProduct()

    //ficha
    const { ficha, setFicha } = useProduct([])
    const { anexosFicha, setAnexosFicha } = useProduct([])
    const { addedFilesFicha, setAddedFilesFicha } = useProduct()
    const { selectedFileFicha, setSelectedFileFicha } = useProduct()

    //demais anexos
    const { anexos, setAnexos } = useProduct()
    const { fotos, setFotos } = useProduct([])
    const { addedFiles, setAddedFiles } = useProduct()
    const { selectedFile, setSelectedFile } = useProduct([])

    let validated = []

    const [loading, setLoading] = useState([])
    const [sitesData, setSitesData] = useState([])
    const [areaUseData, setAreaUseData] = useState([])
    const [productGroupData, setProductGroupData] = useState([])
    const [product, setProduct] = useState([])

    const [name, setName] = useState('')
    const [id, setId] = useState('')
    const [valueFISPQ, setValueFISPQ] = useState('')

    async function getIdentify() {
        setLoading(true)
        const isName = sessionStorage.getItem('name');
        const userId =  sessionStorage.getItem('userId');
        setName(isName)
        setId(userId)
        setLoading(false)
    }

    async function getSites(){
        setLoading(true)
        let response = await api.get('sites')
        setSitesData(response.data)
        setLoading(false)
    }
    async function getAreasUse() {
        setLoading(true)
        let response = await api.get('areasuse')
        setAreaUseData(response.data)
        setLoading(false)
    }

    async function getProductGroup() {
        setLoading(true)
        let response = await api.get('productsgroup')
        setProductGroupData(response.data)
        setLoading(false)
    }

    useEffect(() => {
        getSites();
        getAreasUse()
        getProductGroup()
        getIdentify()
    }, []);

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            let response = await api.get(`/product/${productId}`)
            setProduct(response.data)
                setResponsibility(response.data.responsibility) 
                setProductGrup(response.data.ProductGroup)
                setSite(response.data.requestingSiteId)
                setActivePrinciple(response.data.activePrinciple)
                setRequestDate(response.data.requestDate)
                setProductAction(response.data.productAction)
                setProductName(response.data.productName)
                setControlledPFFAPC(response.data.controlledPFFAPC)
                setManufacturer(response.data.manufacturer)
                setQualityApproval(response.data.qualityApproval)
                setRETProduct(response.data.RETProduct)
                setSiteUse(response.data.siteUse)
                setAreasUse(response.data.AreasUse)
                setActivityUsed(response.data.activityProductUsed)
                setIsMoc(response.data.MOCOpened)

            let responseAttachments = await api.get(`/attachments/products/${productId}`)
            let FISPQ = []
            let ficha = []
            let anexo = []
            responseAttachments.data?.forEach(attachment => {

                if (attachment.typeAttachments == 'FISPQ') {
                    FISPQ.push(attachment)
                } else if (attachment.typeAttachments == 'ficha') {
                    ficha.push(attachment)
                } else {
                    anexo.push(attachment)
                }
            })
            setAddedFilesFISPQ(FISPQ)
            setAddedFilesFicha(ficha)
            setAddedFiles(anexo)
            //setSaveFISPQ(FISPQ)
            // setSaveFicha(ficha)
            // setSaveAnexos(anexo)
            setLoading(false)
        }
        fetchData();
    }, []);
    function validation() {

        validated = true
        if (id == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um cargo antes de submeter.`
            })
            validated = false
            return false
        }
        if (responsibility == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um cargo antes de submeter.`
            })
            validated = false
            return false
        }
        if (productGrup == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um grupo de produto antes de submeter.`
            })
            validated = false
            return false
        }
        if (site == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um site antes de submeter.`
            })
            validated = false
            return false
        }
        if (activePrinciple == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um ativo principal antes de submeter.`
            })
            validated = false
            return false
        }
        if (evaluationDate == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir uma data de solicitação antes de submeter.`
            })
            validated = false
            return false
        }
        if (productAction == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir um produto ativo antes de submeter.`
            })
            validated = false
            return false
        }
        if (productName == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir o nome do produto antes de submeter.`
            })
            validated = false
            return false
        }
        if (manufacturer == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir o fabricante antes de submeter.`
            })
            validated = false
            return false
        }
        if (siteUse == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir os sites que serão utilizados antes de submeter.`
            })
            validated = false
            return false
        }
        if (areasUse == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir a área de uso antes de submeter.`
            })
            validated = false
            return false
        }

        if (activityUsed == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir a atividade de uso antes de submeter.`
            })
            validated = false
            return false
        }
        if (isMoc == undefined) {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir se foi aberto um MOC antes de submeter.`
            })
            validated = false
            return false
        }
        if(valueFISPQ == ''){ 
            Toast.fire({
                icon: 'error',
                title: 'Inclusão inválida! É necessário inserir o vencimento do FISPQ antes de submeter.'
            })
        }
    }


    async function handleSubmit() {
        validation();
        if (validated == false) {
            return false
        }
        try {
            setLoading(true)
            let response = await api.put(`save/product/${productId}`, {
                "createdBy": id,
                "productName": productName,
                "requestDate": moment(evaluationDate).format('YYYY-MM-DD'),
                "areasUseId": areasUse,
                "responsibility": responsibility,
                "requestingSiteId": site,
                "siteUseId": siteUse,
                "activityProductUsed": activityUsed,
                "MOCOpened": isMoc,
                "productGroupId": productGrup,
                "activePrinciple": activePrinciple,
                "RETProduct": RETProduct,
                "controlledPFFAPC": controlledPFFAPC,
                "qualityApproval": qualityApproval,
                "productAction": productAction,
                "manufacturer": manufacturer,
                "FISPQDueDate": valueFISPQ,
                "status": "Aguardando aprovação"
            })

            if (response.status == 200) {
                if (fotos.length != 0) {
                    fotos.map(async (image) => {
                         await addAttachment('attachments/products', {
                            "productId": response.data.id,
                            "typeAttachments": "anexo",
                            "file": image
                        })
                    })
                }
                if (anexos.length != 0) {
                    anexos.map(async (image) => {
                         await addAttachment('attachments/products', {
                            "productId": response.data.id,
                            "typeAttachments": "anexo",
                            "file": image
                        })
                    })
                }
                if (FISPQ.length != 0) {
                    FISPQ.map(async (image) => {
                         await addAttachment('attachments/products', {
                            "productId": response.data.id,
                            "typeAttachments": "FISPQ",
                            "file": image
                        })
                    })
                }
                if (ficha.length != 0) {
                    ficha.map(async (image) => {

                       await addAttachment('attachments/products', {
                            "productId": response.data.id,
                            "typeAttachments": "ficha",
                            "file": image
                        })
                    })
                }
                Swal.fire({
                    title: 'Produto Cadastrado !',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#009EBE',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/products')
                    }
                })
                setResponsibility('')
                setProductGrup('')
                setSite('')
                setActivePrinciple('')
                setRequestDate('')
                setProductAction('')
                setProductName('')
                setControlledPFFAPC('')
                setManufacturer('')
                setQualityApproval('')
                setRETProduct('')
                setSiteUse('')
                setAreasUse('')
                setActivityUsed('')
                setIsMoc('')
                setFISPQ([])
                setFicha([])
                setAnexos([])
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data}`
            })
        }
        setLoading(false)
    }


    return (
        <>
            {loading == true ? (
                <div className="spinner">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div></div>)}
            <Header funcao={() => navigate('/products')} />
            <div className={style.background}>
                <div className={style.main}>
                    <TitlePages icon={iconSearch} styles={{ fontSize: '1.2rem' }} title='Solicitar Produto' />
                    
                    <div className={style.container}>
                        <div className={style.title}>
                            <h2>Informações Gerais</h2>
                        </div>
                        <div className={style.mainProduct}>
                            <div className={style.divProduct}>
                                <TextRequest label={'Solicitante* :'} />
                                <input value={name} disabled className={style.inputProduct} />
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Data Solicitação* :' />
                                <input type='date' disabled className={style.inputProduct} value={evaluationDate} onChange={(e) => setEvaluationDate(e.target.value)} />
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Cargo* :' />
                                <input value={responsibility} onChange={(e) => setResponsibility(e.target.value)} className={style.inputProduct} />
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Site solicitante* :' />
                                <select className={style.selectProduct} value={site} multiple={false} onChange={(e) => { setSite(e.target.value) }}>
                                    <option value=""></option>
                                    {sitesData?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                                </select>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Nome do Produto* :' />
                                <input value={productName} onChange={(e) => setProductName(e.target.value)} className={style.inputProduct} />
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Fabricante* :' />
                                <input value={manufacturer} onChange={(e) => setManufacturer(e.target.value)} className={style.inputProduct} />
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Grupo de produto* :' />
                                <select className={style.selectProduct} value={productGrup} onChange={(e) => setProductGrup(e.target.value)} multiple={false}>
                                    <option value=""></option>
                                    {productGroupData?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                                </select>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Princípio Ativo* :' icon={true} info='Consultar informação na FISPQ' />
                                <input value={activePrinciple} onChange={(e) => setActivePrinciple(e.target.value)} className={style.inputProduct} />
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Ação do produto* :' />
                                <input value={productAction} onChange={(e) => setProductAction(e.target.value)} className={style.inputProduct} />
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Controlado por PF/FA/PC ?*' icon={true} info='Polícia Federal, Forças Armadas e Polícia Civil' />
                                <select className={style.selectProduct} onChange={(e) => setControlledPFFAPC(e.target.value)} value={controlledPFFAPC}>
                                    <option value=''> </option>
                                    <option value='true'> Sim</option>
                                    <option value="false"> Não</option>
                                </select>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Produto é RET ?*' icon={true} info='Produto é RET' />
                                <select className={style.selectProduct} onChange={(e) => setRETProduct(e.target.value)} value={RETProduct} >
                                    <option value=''> </option>
                                    <option value='true'> Sim</option>
                                    <option value="false"> Não</option>
                                </select>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Foi aberto um MOC ?*' />
                                <select className={style.selectProduct} onChange={(e) => setIsMoc(e.target.value)} value={isMoc} >
                                    <option value=''> </option>
                                    <option value='true'> Sim</option>
                                    <option value="false"> Não</option>
                                </select>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Necessita de aprovação da equipe de Qualidade ?*' />
                                <select onChange={(e) => setQualityApproval(e.target.value)} value={qualityApproval} className={style.selectProduct} >
                                    <option value=''> </option>
                                    <option value='true'> Sim</option>
                                    <option value="false"> Não</option>
                                </select>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Sites para utilização* :' />
                                <select className={style.selectProduct} value={siteUse} multiple={false} onChange={(e) => { setSiteUse(e.target.value) }}>
                                    <option value=""></option>
                                    {sitesData?.map(x => <option key={x.id} value={x.name}>{x.name}</option>)}
                                </select>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Áreas de utilização* :' />
                                <select className={style.selectProduct} onChange={(e) => setAreasUse(e.target.value)} multiple={false}>
                                    <option value=""></option>
                                    {areaUseData?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                                </select>
                            </div>
                            <div className={style.divProduct}>
                                <TextRequest label='Em qual atividade será utilizado o produto ?*' />
                                <input className={style.inputProduct} onChange={(e) => setActivityUsed(e.target.value)} value={activityUsed} />
                            </div>

                        </div>
                    </div>
                    <div className={style.containerAnexos}>
                        <div className={style.mainAnexos}>
                            <TextPages title='Anexos' />
                            <div className={style.divAnexos}>
                                <UploadFile
                                    label={'FISPQ'}
                                    selectedFile={selectedFileFISPQ}
                                    setSelectedFile={setSelectedFileFISPQ}
                                    anexos={FISPQ}
                                    setAnexos={setFISPQ}
                                    addedFiles={addedFilesFISPQ}
                                    setAddedFiles={setAddedFilesFISPQ}
                                    valueFISPQ={valueFISPQ}
                                    setValueFISPQ={setValueFISPQ}
                                />
                                <UploadFile
                                    label={'Ficha de Emergência'}
                                    selectedFile={selectedFileFicha}
                                    setSelectedFile={setSelectedFileFicha}
                                    anexos={ficha}
                                    setAnexos={setFicha}
                                    addedFiles={addedFilesFicha}
                                    setAddedFiles={setAddedFilesFicha}
                                />
                                <UploadFile
                                    label={'Demais anexos'}
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    anexos={anexos}
                                    setAnexos={setAnexos}
                                    addedFiles={addedFiles}
                                    setAddedFiles={setAddedFiles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.divButton}>
                        <button onClick={() => navigate('/products')}>Cancelar</button>
                        <div className={style.buttons}>
                            <button onClick={() => handleSubmit()}>Submeter</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}