import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import style from '../../administration/adm.module.css'
import { api } from "../../../services/api";

import Table from 'rc-table';
import Swal from 'sweetalert2';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { HiUsers } from 'react-icons/hi'
import { FaCheck, FaTimes, FaTrash } from 'react-icons/fa';
import { RiPencilFill } from 'react-icons/ri'

import TitlePages from "../../../components/titleConfigs";
import Label from "../../../components/label";
import AddButton from "../../../components/buttonAdd";
import Spinner from '../../../components/spinner';

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    width: 400,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default function Approvals() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState([])

    const [approvals, setApprovals] = useState('')
    const [allApprovals, setAllApprovals] = useState([])
    const [users, setUsers] = useState([])
    const [sites, setSites] = useState([])
    const [areaApprovals, setAreaApprovals] = useState([])

    const [approvalsId, setApprovalsId] = useState('')
    const [approvalsBackupId, setApprovalsBackupId] = useState('')

    const [selectedApprovals, setSelectedApprovals] = useState('')
    const [selectedApprovalsBackup, setSelectedApprovalsBackup] = useState('')
    const [selectSites, setSelectSites] = useState('')
    const [selectAreaApprovals, setSelectAreaApprovals] = useState('')
    const [selectTime, setSelectTime] = useState('')

    //edit
    const [editId, setEditId] = useState('Id');
    const [valueEditSite, setValueEditSite] = useState([])
    const [valueEditArea, setValueEditArea] = useState([])
    const [valueEditApprovals, setValueEditApprovals] = useState([])
    const [valueEditApprovalsBackup, setValueEditApprovalsBackup] = useState([])
    const [valueEditTime, setValueEditTime] = useState([])

    const [valueEditSiteId, setValueEditSiteId] = useState([])
    const [valueEditAreaId, setValueEditAreaId] = useState([])

    const [inEdit, setInEdit] = useState(false)

    async function getUsers() {
        setLoading(true)
        let responseUsers = await api.get('users')
        let usersArray = []
        responseUsers.data.map(x => {
            usersArray.push({ label: x.name, value: x.id })
        })
        setUsers(usersArray)
        setLoading(false)
    }

    async function getSites() {
        setLoading(true)
        let responseSites = await api.get('/sites')
        setSites(responseSites.data)
        setLoading(false)
    }

    async function getAreaApprovals() {
        setLoading(true)
        let response = await api.get('/areaapprovals')
        setAreaApprovals(response.data)
        setLoading(false)
    }

    async function getAllApprovals() {
        setLoading(true)
        let response = await api.get('/approvals')
        setAllApprovals(response.data)
        setLoading(false)
    }

    async function loadingApproval(site) {
        setLoading(true)
        let allApproval = await api.get('/approvals')
        if (site !== '') {
            let filter = allApproval.data.filter(x => x.siteId == site)
            setApprovals(filter)
        } else {
            await getAllApprovals()
            setApprovals(allApprovals)
        }
        setLoading(false)
    }

    async function loadingApprovals(site, area) {
        setLoading(true)
        let allApprovals = await api.get('/approvals')
        if (area !== '') {
            let filter = allApprovals.data.filter(x => x.siteId == site && x.areaApprovalsId == area)
            setApprovals(filter)
        } else  if (site !== ''){
            let filter = allApprovals.data.filter(x => x.siteId == site)
            setApprovals(filter)
        }else {
            await getAllApprovals()
            setApprovals(allApprovals)
        }
        setLoading(false)
    }

    useEffect(() => {
        getUsers();
        getSites()
        getAreaApprovals()
        getAllApprovals()
    }, []);

    const columns = [
        {
            title: "Sites",
            dataIndex: ["Sites", "name"],
            key: "name",
            align: "left",
            width: '20%',
            render: (val, obj) => Number(editId) === Number(obj.id) ?
                <select className="selectPadrao" value={valueEditSiteId} multiple={false} onChange={(e) => { setValueEditSite(e.target.value); setInEdit(true); setValueEditSiteId(e.target.value)}}>
                    {sites?.map(x => <option key={x.id} value={x.id}>{x.sigla}</option>)}
                </select> : <>{val}</>
        },
        {
            title: "Área de aprovação",
            dataIndex: ["AreaApprovals", "name"],
            key: "name",
            align: "left",
            width: '20%',
            render: (val, obj) => Number(editId) === Number(obj.id) ?
                <select className="selectPadrao" value={valueEditAreaId} multiple={false} onChange={(e) => { setInEdit(true);setValueEditAreaId(e.target.value) }}>
                    {areaApprovals?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                </select> : <>{val}</>
        },
        {
            title: "Aprovador",
            dataIndex: ["Users", "name"],
            key: "name",
            align: "left",
            width: '20%',
            render: (val, obj) => Number(editId) === Number(obj.id) ?
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    sx={{ width: 200 }}
                    options={users}
                    value={valueEditApprovals}
                    onInputChange={(event, newInputValue) => {
                        setInEdit(true);
                        setValueEditApprovals(newInputValue)
                    }}
                    onChange={(event, value) => {
                        handleSelectedApprovals(value)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps
                            }}
                        />
                    )}
                /> : <>{val}</>
        },
        {
            title: "Aprovador de Backup",
            dataIndex: ["UserBackup", "name"],
            key: "name",
            align: "left",
            width: '20%',
            render: (val, obj) => Number(editId) === Number(obj.id) ?
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    sx={{ width: 200 }}
                    options={users}
                    value={valueEditApprovalsBackup}
                    onInputChange={(event, newInputValue) => {
                        setInEdit(true);
                        setValueEditApprovalsBackup(newInputValue)
                    }}
                    onChange={(event, value) => {
                        handleSelectedApprovalsBackup(value)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps
                            }}
                        />
                    )}
                /> : <>{val}</>
        },
        {
            title: "Prazo",
            dataIndex: ["time"],
            key: "name",
            align: "left",
            width: '15%',
            render: (val, obj) => Number(editId) === Number(obj.id) ?
                <select className="selectPadrao" value={valueEditTime} multiple={false} onChange={(e) => { setValueEditTime(e.target.value); setInEdit(true); }}>
                    <option value="7"> 7 dias</option>
                    <option value="14"> 14 dias</option>
                    <option value="30"> 30 dias</option>
                </select> : <>{val}</>
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            align: "right",
            with: '10%',
            render: (value, row, index) => (<div style={{ display: 'flex', height: ' 1.2rem', fontSize: '0.8rem', alignItems: 'center' }}>
                <div className="actionBtn d-none" onClick={(e) => onsubmitEdit(e, row.id)} style={{ display: 'flex', cursor: 'pointer',  width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><FaCheck color='#5A5A5A' size={14} /></div>&nbsp;
                <div className="actionBtn d-none" onClick={onCancelEdit} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><FaTimes color='#5A5A5A' size={14} /></div>
                <div className="actionBtn" onClick={(e) => onEdit(e, row)} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><RiPencilFill color='#5A5A5A' size={14} /></div>&nbsp;&nbsp;
                <div className="actionBtn" onClick={() => handleRemove(row.id)} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><FaTrash color='#5A5A5A' size={14} /></div></div>
            )
        },
    ];

    function handleRemove(id) {
        Swal.fire({
            title: 'Deletar Aprovador?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#009EBE",
            cancelButtonColor: "#636e72",
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true)
                    let response = await api.delete(`/approvals/${id}`)
                    if (response.status == 200) {
                        Toast.fire({
                            title: 'Aprovador deletado',
                            icon: 'success'
                        })
                    }
                    await loadingApprovals(selectSites, selectAreaApprovals)
                    setLoading(false)
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: `${error.response.data}`
                    })
                }
            }
        })
    }

    const [errorSelectSites, setErroSelectSites] = useState('')
    const [errorAreaApprovals, setErrorAreaApprovals] = useState('')
    const [errorApprovals, setErroApprovals] = useState('')
    const [errorApprovalsBackup, setErroApprovalsBackup] = useState('')
    const [errorTime, setErroTime] = useState('')

    let validated = []
    function validation() {
        validated = true
        if (approvalsId == '') {
            setErroApprovals('Inclusão inválida! É necessário pesquisar o colaborador e clicar em seu nome antes de adicionar.')
            validated = false
            return false
        }
        if (approvalsBackupId == '') {
            setErroApprovalsBackup('Inclusão inválida! É necessário pesquisar o colaborador e clicar em seu nome antes de adicionar.')
            validated = false
            return false
        }
        if (selectTime == '') {
            setErroTime('Inclusão inválida! É necessário selecionar um prazo antes de adicionar.')
            validated = false
            return false
        }
        if (selectAreaApprovals == '') {
            loadingApprovals('Inclusão inválida! É necessário selecionar uma área antes de adicionar.')
            validated = false
            return false
        }
        if (selectSites == '') {
            setErroSelectSites('Inclusão inválida! É necessário selecionar um sites antes de adicionar.')
            validated = false
            return false
        }
    }

    async function addAprovador() {
        validation();

        if (validated == false) {
            return false
        }
        try {
            setLoading(true)
            let response = await api.post(`approvals`, {
                userId: approvalsId,
                backupUserId: approvalsBackupId,
                areaApprovalsId: selectAreaApprovals,
                siteId: selectSites,
                time: selectTime
            })
            if (response.status == 200) {
                Toast.fire({
                    title: 'Aprovador adicionado',
                    icon: 'success'
                })
            }
            await loadingApprovals(selectSites, selectAreaApprovals)
            setSelectedApprovals('')
            setSelectedApprovalsBackup('')
            setSelectTime([])
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data}`
            })
        }
        setLoading(false)
    }

    function handleSelectedApprovals(value) {
        setApprovalsId(value.value)
    }

    function handleSelectedApprovalsBackup(value) {
        setApprovalsBackupId(value.value)
    }
    // edit
    async function onsubmitEdit(evt, id) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = true
        }
        for (const btn of tdActionButtons) {
            btn.classList.toggle("d-none")
        }
        try {

            setLoading(true)
            let response = []
            if (inEdit == true) {
                let responseName = await api.put(`approvals/${id}`, {
                    userId: approvalsId,
                    backupUserId: approvalsBackupId,
                    areaApprovalsId: valueEditAreaId,
                    siteId: valueEditSiteId,
                    time: valueEditTime
                })
                if (responseName.status == 200) {
                    response = true
                }
            }

            if (response == true) {
                Toast.fire({
                    icon: 'success',
                    title: 'Registro alterado com sucesso!'
                })
            }
            await loadingApprovals(selectSites, selectAreaApprovals)
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data}`
            })
        }

        setEditId('Id')
        setInEdit(false)
        await loadingApprovals(selectSites, selectAreaApprovals)
        setLoading(false)
    }

    async function onEdit(evt, row ) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const itemId = element.getAttribute('data-row-key')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = false
        }
        for (const btn of tdActionButtons) {
            btn.classList.toggle("d-none")
        }
        setEditId(itemId)
        setValueEditSite(row.Site?.name)
        setValueEditArea(row.AreaApprovals?.name)

        setValueEditApprovals(row.Users?.name)
        setValueEditApprovalsBackup(row.UserBackup?.name)
        setValueEditTime(row.time)

        setApprovalsId(row.userId)
        setApprovalsBackupId(row.backupUserId)
        setValueEditSiteId(row.siteId)
        setValueEditAreaId(row.areaApprovalsId)
    }

    function onCancelEdit(evt) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = true
        }
        for (const btn of tdActionButtons) {
            btn.classList.toggle("d-none")
        }
        setEditId('Id')
        setInEdit(false)
        setValueEditSite([])
        setValueEditArea([])
        setValueEditApprovals([])
        setValueEditApprovalsBackup([])
        setValueEditTime([])
    }

    return (
        <>
            <div className={style.admContainer}>
                {loading == true ? (
                    <div className="spinner">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <div></div>
                )}
                <TitlePages icon={<HiUsers color='#009ebe' size={21} />} title='Aprovadores' funcao={() => navigate('/home')} />
                <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-end' }}>
                    <div style={{ width: '100%'}} >
                        <Label>Site</Label>
                        <div>
                            <div>
                                <select className="selectPadrao" value={selectSites} multiple={false} onChange={(e) => { setSelectSites(e.target.value); loadingApproval(e.target.value); setErroSelectSites([]) }}>
                                    <option value="">Todos</option>
                                    {sites?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                                </select>
                            
                            </div>
                        </div>
                    </div>
                    {selectSites.length > 0 ?
                        <div style={{ width: '100%'}}>
                            <Label>Area de Aprovação</Label>
                            <div>
                                <div>
                                    <select className="selectPadrao" value={selectAreaApprovals} multiple={false} onChange={(e) => { setSelectAreaApprovals(e.target.value); loadingApprovals(selectSites, e.target.value); setErrorAreaApprovals([]) }}>
                                        <option value="">Todos</option>
                                        {areaApprovals?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                                    </select>
                                   
                                </div>
                            </div>
                        </div> : <></>}
                    {selectAreaApprovals.length > 0 && selectSites.length > 0 ?
                        <div>
                            <Label>Aprovador</Label>
                            <div>
                                <Autocomplete
                                    freeSolo
                                    id="free-solo-2-demo"
                                    disableClearable
                                    sx={{ width: 200 }}
                                    options={users}
                                    value={selectedApprovals}
                                    onInputChange={(event, newInputValue) => {
                                        setErroApprovals('')
                                        setApprovalsId('')
                                        setSelectedApprovals(newInputValue)
                                    }}
                                    onChange={(event, value) => {
                                        handleSelectedApprovals(value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps
                                            }}
                                        />
                                    )}
                                />
                            </div>
                           
                        </div> : <></>}
                    {selectSites.length > 0 && selectedApprovals.length > 0 && selectAreaApprovals.length > 0 ?
                        <div>
                            <Label>Aprovador de backup</Label>
                            <div>
                                <Autocomplete
                                    freeSolo
                                    id="free-solo-2-demo"
                                    disableClearable
                                    sx={{ width: 200 }}
                                    options={users}
                                    value={selectedApprovalsBackup}
                                    onInputChange={(event, newInputValue) => {
                                        setErroApprovalsBackup('')
                                        setApprovalsBackupId('')
                                        setSelectedApprovalsBackup(newInputValue);
                                    }}
                                    onChange={(event, value) => {
                                        handleSelectedApprovalsBackup(value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps
                                            }}
                                        />
                                    )}
                                />
                            </div>
                          
                        </div> : <></>}
                    {selectSites.length > 0 && selectedApprovals.length > 0 && selectAreaApprovals.length > 0 && selectedApprovalsBackup.length > 0 ?
                        <div style={{ display: 'flex', width: '100%', }}>
                            <div style={{ width: '100%'}}>
                                <Label>Prazo</Label>
                                <div>
                                    <select className="selectPadrao" value={selectTime} multiple={false} onChange={(e) => { setSelectTime(e.target.value); setErroTime([]) }}>
                                        <option value=''>Todos</option>
                                        <option value="7"> 7 dias</option>
                                        <option value="14"> 14 dias</option>
                                        <option value="30"> 30 dias</option>
                                    </select>
                                   
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: '10px' }}>
                                <AddButton onClick={() => addAprovador()} />
                            </div>
                        </div> : <></>}
                </div>
                {selectSites.length > 0 ?
                    <>
                        {approvals.length > 0 ?
                            <div style={{ width: '100%', height: '52vh', overflow: 'auto', padding: '0.1rem', marginTop: '0.5rem' }}>
                                <div>
                                    <Table
                                        sticky={true}
                                        columns={columns}
                                        showHeader={true}
                                        data={approvals}
                                        rowKey="id"
                                        emptyText=""
                                    />
                                </div>
                            </div> :
                            <div className="emptyArray">Nenhum aprovador cadastrado para o site e área selecionadas!</div>}</> :
                    <>
                        {allApprovals.length > 0 ?
                            <div style={{ width: '100%', height: '52vh', overflow: 'auto', padding: '0.1rem', marginTop: '0.5rem',  height: '70%' }}>
                                <Table
                                    sticky={true}
                                    columns={columns}
                                    showHeader={true}
                                    data={allApprovals}
                                    rowKey="id"
                                    emptyText="Nenhum aprovador cadastrado!"
                                />

                            </div> : <div className="emptyArray">Nenhum aprovador cadastrado para o site e área selecionadas!</div>}
                    </>}
            </div>
        </>
    )
}