import React from "react";
import { Link } from "react-router-dom";

import style from './button.module.css'

export default function ButtonHome(props) {
    return (
        <div className={style.divButtonHome} style={props.active === false ?{filter:' opacity(0.5) grayscale(1)', pointerEvents:'none'}: {}}>
            {props.route != 'false' ?
            <Link exact="true" to={`${props.route}`}>
                <span>{props.number}</span>
                <button className={style.buttonHome}>
                    <img alt='icon' src={props.icon} />
                    <label>{props.label}</label>
                </button>
            </Link>:
            <>
             <span>{props.number}</span>
             <button onClick={props.funcao} className={style.buttonHome}>
                 <img alt='icon' src={props.icon} />
                 <label>{props.label}</label>
             </button>
             </>}
        </div>
    )
}