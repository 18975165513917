import { useEffect, useState } from "react";

import Chart from 'react-apexcharts'
import moment from "moment";

import LabelForms from '../../../components/labelForms'

import style from '../reports.module.css'

export default function ChartProductByStatus({products}) {
    const [series, setSeries] = useState([]);

    const optionsStatus = {
        labels: ['Homologado', 'Reprovado', 'Em análise', 'Aguardando aprovação'],
        plotOptions: {
          pie: {
            donut: {
              size: '50%',
            },
          },
        },
       
          legend: {
            position: 'bottom',
            offsetY: 0,
       },
        
        colors:['#77dd77', '#ff6961', '#fdfd96', '#f9d99a'],
    }

  
    useEffect(() => {
        var SerieHomologado = products.filter((e) => e.status === 'Homologado')
        var SerieIndeferido = products.filter((e) => e.status === 'Reprovado')
        var SerieEmAnalise = products.filter((e) => e.status === 'Em análise')
        var SerieAguardando = products.filter((e) => e.status === 'Aguardando aprovação')

        setSeries([SerieHomologado.length, SerieIndeferido.length, SerieEmAnalise.length, SerieAguardando.length ])
    }, [products]);
    return (
        <>
            <div className={style.chart}>
                <LabelForms>Produtos por status</LabelForms>
                <Chart options={optionsStatus} series={series} type="donut" />
            </div>
        </>
    )
}