import React from "react";
import style from "./text.module.css";
import { LightTooltip } from '../tooltip/index'

export default function TextProduct(props) {

  return (
    <>
      <div className={style.textProduct}>
        <label>{props.label}
          {props.icon ?
            props.icon : null
          }</label>

        <span style={props.color == true ? { color: '#009EBE' } : {}}>
        {props.name?.length >= 50?
        <LightTooltip placement="left" style={{ with: 'flex'}}  title={props.name}>
          <p style={{  overflow: "hidden",whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{props.name}</p>
          </LightTooltip> :
        <p>{props.name}</p>}
          
          {props.edit ?
            <img src={props.edit} /> : null
          }
        </span>
      </div>
    </>
  )
}