import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import style from '../../administration/adm.module.css'
import { api } from "../../../services/api";

import Table from 'rc-table';
import Swal from 'sweetalert2';

import { MdPieChart } from 'react-icons/md'
import { FaCheck, FaTimes, FaTrash } from 'react-icons/fa';
import { RiPencilFill } from 'react-icons/ri';

import TitlePages from "../../../components/titleConfigs";
import Label from "../../../components/label";
import AddButton from "../../../components/buttonAdd";
import Spinner from '../../../components/spinner';

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    width: 400,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default function ProductGrup() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState([])
    const [productGrup, setProductGrup] = useState([])
    const [newProductGrup, setNewProductGrup] = useState([])
    const [errorProductGrup, setErrorProductGrup] = useState([])

    const [editId, setEditId] = useState('Id');
    const [valueEdit, setValueEdit] = useState([])
    const [inEdit, setInEdit] = useState(false)

    async function getProductGrup() {
        setLoading(true)
        let response = await api.get('productsgroup')
        setProductGrup(response.data)
        setLoading(false)
    }

    useEffect(() => {
        getProductGrup();
    }, []);


    const columns = [
        {
            title: "ProductGroup",
            dataIndex: ["name"],
            key: "name",
            align: "left",
            width: '95%',
            render: (val, obj) => Number(editId) === Number(obj.id) ? <input className="inputPadrao" value={valueEdit} onChange={(e) => { setValueEdit(e.target.value); setInEdit(true) }}></input> : <>{val}</>

        },
        {
            title: '',
            dataIndex: '',
            key: '',
            align: "right",
            with: '10%',
            render: (value, row, index) => (<div style={{ display: 'flex', height: ' 1.2rem', fontSize: '0.8rem', alignItems: 'center' }}>
                <div className="actionBtn d-none" onClick={(e) => onsubmitEdit(e, row.id)} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><FaCheck color='#5A5A5A' size={14} /></div>&nbsp;
                <div className="actionBtn d-none" onClick={onCancelEdit} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><FaTimes color='#5A5A5A' size={14} /></div>
                <div className="actionBtn" onClick={(e) => onEdit(e, row.name)} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><RiPencilFill color='#5A5A5A' size={14} /></div>&nbsp;&nbsp;
                <div className="actionBtn" onClick={() => handleRemove(row.id)} style={{ display: 'flex', cursor: 'pointer', width: '1.5rem', height: '1.5rem', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}><FaTrash color='#5A5A5A' size={14} /></div></div>)

        },
    ];

   let validated = []
    function validation() {
        validated = true
        if (newProductGrup == '') {
            setErrorProductGrup('Inclusão inválida! É necessário inserir um nome antes de adicionar.')
            validated = false
            return false
        } 
    }

    async function addProductGrup() {
        validation();
        if (validated == false) {
            return false
        }
        try {
            setLoading(true)
            let response = await api.post(`/productsgroup`, {
                name: newProductGrup
            })
            if (response.status == 200) {
                Toast.fire({
                    title: 'Grupo de produto cadastrado',
                    icon: 'success'
                })
            }
            await getProductGrup();
            setNewProductGrup('')
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data}`
            })
        }
        setLoading(false)
    }
    function handleRemove(id) {
        Swal.fire({
            title: 'Deletar Grupo do produto?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#009EBE",
            cancelButtonColor: "#636e72",
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true)
                    let response = await api.delete(`/productsgroup/${id}`)
                    if (response.status == 200) {
                        Toast.fire({
                            title: 'Grupo de produto deletado',
                            icon: 'success'
                        })
                    }
                    await getProductGrup();
                    setLoading(false)
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: `${error.response.data}`
                    })
                }
            }
        })
    }

    async function onsubmitEdit(evt, id) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = true
        }
        for (const btn of tdActionButtons) {
            btn.classList.toggle("d-none")
        }
        try {
            setLoading(true)
            let response = []
            if (inEdit == true) {
                let responseName = await api.put(`/productsgroup/${id}`, {
                    name: valueEdit
                })
                if (responseName.status == 200) {
                    response = true
                }
            }
            if (response == true) {
                Toast.fire({
                    icon: 'success',
                    title: 'Registro alterado com sucesso!'
                })
            }

        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data}`
            })
        }
        setEditId('Id')
        setInEdit(false)
        await getProductGrup()
        setLoading(false)
    }

    async function onEdit(evt, name, anexo) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const itemId = element.getAttribute('data-row-key')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = false
        }
        for (const btn of tdActionButtons) {
            btn.classList.toggle("d-none")
        }
        setEditId(itemId)
        setValueEdit(name)
    }

    function onCancelEdit(evt) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = true
        }
        for (const btn of tdActionButtons) {
            btn.classList.toggle("d-none")
        }
        setEditId('Id')
        setInEdit(false)
        setValueEdit([])
    }

    return (
        <>
            <div className={style.admContainer}>
                {loading == true ? (
                    <div className="spinner">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <div></div>
                )}
                <TitlePages icon={<MdPieChart color='#009ebe' size={21} />} title='Grupo de Produtos' />
                <div>
                    <Label>Grupo de Produtos</Label>
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-end', marginTop: '0.25rem', width: '27vw' }}>
                        <input className={style.inputMain} value={newProductGrup} 
                          placeholder="Novo Grupo de Produtos"
                          onChange={(e) => {
                            setErrorProductGrup('')
                            setNewProductGrup(e.target.value)
                        }}
                        />
                        <AddButton onClick={() => addProductGrup()} />
                    </div>
                    {errorProductGrup ? (
                        <div className="inputError">{errorProductGrup}</div>
                    ) : null}
                </div>

                <div style={{ width: '27vw', overflow: 'auto', padding: '0.1rem',  height: '70%'}}>
                    <Table
                        sticky={true}
                        columns={columns}
                        showHeader={false}
                        data={productGrup}
                        rowKey="id"
                        emptyText="Nenhum grupo de produtos cadastrado!"
                    />
                </div>
            </div>
        </>
    )
}