import React, { useState , useEffect} from 'react';

export const ProductContext = React.createContext({});


export const ProductProvider = (props) => {

    const [responsibility, setResponsibility] = useState()
    const [productGrup, setProductGrup] = useState()
    const [site, setSite] = useState()
    const [activePrinciple, setActivePrinciple] = useState()
    const [requestDate, setRequestDate] = useState()
    const [productAction, setProductAction] = useState()
    const [productName, setProductName] = useState()
    const [controlledPFFAPC, setControlledPFFAPC] = useState()
    const [manufacturer, setManufacturer] = useState()
    const [qualityApproval, setQualityApproval] = useState()
    const [RETProduct, setRETProduct] = useState()
    const [siteUse, setSiteUse] = useState()
    const [areasUse, setAreasUse] = useState()
    const [isCarbamate, setIsCarbamate] = useState()
    const [isOrganophosphate, setIsOrganophosphate] = useState()
    const [activityUsed, setActivityUsed] = useState()
    const [isMoc, setIsMoc ] = useState()

    const [selectedFileFISPQ, setSelectedFileFISPQ] = useState(null)
    const [addedFilesFISPQ, setAddedFilesFISPQ] = useState([])
    const [FISPQ, setFISPQ] = useState([])
    const [anexosFISPQ, setAnexosFISPQ] = useState([])

    const [selectedFileFicha, setSelectedFileFicha] = useState(null)
    const [ficha, setFicha] = useState([])
    const [anexosFicha, setAnexosFicha] = useState([])
    const [addedFilesFicha, setAddedFilesFicha] = useState([])

    const [selectedFile, setSelectedFile] = useState(null)
    const [anexos, setAnexos] = useState([])
    const [fotos, setFotos] = useState([])
    const [addedFiles, setAddedFiles] = useState([])

    const [openModalSolitacoes, setOpenModalSolitacoes] = useState(false)
    const [openModalAprovacoes, setOpenModalAprovacoes] = useState(false)
    const [ openModalChecklist, setOpenModalChecklist] = useState(false)
    const [isOpenModalSites, setIsOpenModalSites] = useState(false)
    const [isOpenModalReplySites, setIsOpenModalReplySites] = useState(false)

    const [ isOpenModalPic, setIsOpenModalPic] = useState(false)
    const [ uploadFotos, setUploadFotos] = useState([])

    const [ isOpenModalDocs, setIsOpenModalDocs ] =useState(false)
    const [ uploadDocs, setUploadDocs] = useState([])

   
    useEffect(() => {
        if(sessionStorage.getItem('site') !== null){
            setIsOpenModalSites(false)
        }else{
            setIsOpenModalSites(true)
        }
    }, []);

    return (
        <ProductContext.Provider value={{
            responsibility, setResponsibility,
            productGrup, setProductGrup,
            site, setSite,
            activePrinciple, setActivePrinciple,
            requestDate, setRequestDate,
            productAction, setProductAction,
            productName, setProductName,
            controlledPFFAPC, setControlledPFFAPC,
            manufacturer, setManufacturer,
            qualityApproval, setQualityApproval,
            RETProduct, setRETProduct,
            siteUse, setSiteUse,
            areasUse, setAreasUse,
            isCarbamate, setIsCarbamate,
            isOrganophosphate, setIsOrganophosphate,
            activityUsed, setActivityUsed,
            selectedFileFISPQ, setSelectedFileFISPQ,
            addedFilesFISPQ, setAddedFilesFISPQ,
            FISPQ, setFISPQ,
            anexosFISPQ, setAnexosFISPQ,
            selectedFileFicha, setSelectedFileFicha,
            ficha, setFicha,
            anexosFicha, setAnexosFicha,
            addedFilesFicha, setAddedFilesFicha,
            selectedFile, setSelectedFile,
            anexos, setAnexos,
            fotos, setFotos,
            addedFiles, setAddedFiles,
            isMoc, setIsMoc,
            openModalSolitacoes, setOpenModalSolitacoes,
            openModalAprovacoes, setOpenModalAprovacoes,
            openModalChecklist, setOpenModalChecklist,
            isOpenModalPic, setIsOpenModalPic,
            uploadFotos, setUploadFotos,
            isOpenModalDocs, setIsOpenModalDocs ,
            uploadDocs, setUploadDocs,
            isOpenModalSites, setIsOpenModalSites,
            isOpenModalReplySites, setIsOpenModalReplySites,
            }}>
            {props.children}
        </ProductContext.Provider>
    )
}


export const useProduct = () => React.useContext(ProductContext)