import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './route/private'

import Home from "./pages/home";
import HomeAdm from "./pages/administration/index";
import SearchProducts from "./pages/searchProducts/index";
import ViewProducts from "./pages/viewProducts/index";
import RequestProducts from "./pages/newProduct";
import SaveProducts from "./pages/saveProduct";

import { ProductProvider } from "./providers/product";
import SearchMyProducts from "./pages/searchMyProducts";
import MyApprovals from "./pages/myApprovals";
import Login from "./pages/login";
import Register from "./pages/register";
import Reset from "./pages/reset";
import RegisterPassword from "./pages/registerPassword";
import Reports from "./pages/reports";

export default function Rotas() {
    return (
        <BrowserRouter>
            <ProductProvider>
                <Routes>
                    <Route element={<PrivateRoutes />}>
                        <Route path="/home" exact element={<Home />} />
                        <Route path="/administration" element={<HomeAdm />} />
                        <Route path="/products" element={<SearchProducts />} />
                        <Route path="/view-product" element={<ViewProducts />} />
                        <Route path="/save-product" element={<SaveProducts />} />
                        <Route path="/new-product" element={<RequestProducts />} />
                        <Route path="/my-product" element={<SearchMyProducts />} />
                        <Route path="/approvals-product" element={<MyApprovals />} />
                        <Route path="/reports-product" element={<Reports />} />
                    </Route>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/reset" element={<Reset />} />
                    <Route path="/register/password/:id" element={<RegisterPassword />} />

                </Routes>
            </ProductProvider>
        </BrowserRouter>
    );
}