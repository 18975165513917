import { useState } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookie from 'js-cookie';

import { CgProfile } from 'react-icons/cg'
import { AiOutlineLine } from 'react-icons/ai'
import icon_setting from '../../assets/icon_config.svg'

import logo from "../../assets/Grupo.png"
import iconApprovals from '../../assets/icon_approvals.svg'
import { LightTooltip } from '../tooltip/index'

import style from "./header.module.css"
import { useProduct } from '../../providers/product'
import ModalAprovações from "../modalAprovacoes";


export default function HeaderHome(props) {
    const navigate = useNavigate()
    const { setOpenModalAprovacoes } = useProduct()
    const [loginMenu, setLoginMenu] = useState(false)

    function Logout() {
        Cookie.remove('token');
        sessionStorage.clear()
        window.location.href = "/";
    }
    return (
        <div className={style.containerHeader}>
            <div className={style.logo}>
                <img alt='logo' src={logo} />
            </div>
            <div className={style.infoUser}>
                {props.isAdmin ?
                    <div className={style.infos} >
                        <LightTooltip title="Minhas Aprovações">
                            <button onClick={() => setOpenModalAprovacoes(true)}><img alt='approvals' src={iconApprovals} /></button>
                        </LightTooltip>
                        <AiOutlineLine color='#fff' size={40} style={{ transform: "rotate(90deg)", marginLeft: '-0.5rem', marginTop: '-0.2rem' }} />
                        <LightTooltip title="Painel Administrativo">
                            <Link exact="true" to='/administration'><img alt='settings' src={icon_setting} /></Link>
                        </LightTooltip>
                    </div> :
                    <>
                        {props.isApprover ?
                            <div style={{ justifyContent: 'flex-end', marginTop: '0.3rem' }} className={style.infos} >
                                <LightTooltip title="Minhas Aprovações">
                                    <button onClick={() => setOpenModalAprovacoes(true)}><img alt='approvals' src={iconApprovals} /></button>
                                </LightTooltip>
                            </div> : null}
                    </>}
                <div className={style.logout} >
                    <CgProfile color='#4FB9F8' size={40} onClick={() => setLoginMenu(c => !c)} />
                    {loginMenu == true ?
                        <button className={style.logoutButton} onClick={() => Logout()} >Logout</button> :
                        null
                    }
                </div>

                <div className={style.nameFunction}>
                    <span><strong>{props.name}</strong></span>
                    <span>{props.isAdmin == true ?
                        'Administrador' :
                        <>
                            {props.isApprover == true ?
                                'Aprovador'
                                : 'Usuário padrão'}
                        </>
                    }</span>
                </div>
            </div>
            <ModalAprovações />
        </div>
    )
}